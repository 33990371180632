import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Card, Table, Badge } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import { AxiosResponse } from 'axios';
import { EntityEditForm, FormInputField, FormCheckField } from '../../components';
import APIClient from '../../services/APIClient';
import { Agent, Cliente } from '../../types/model';

export default function VendedorEdit() {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  const { id } = useParams<{ id: string }>();

  const [isAdding] = useState<boolean>(typeof id === 'undefined');
  const [entity, setEntity] = useState<Agent>();
  const [allClients, setAllClients] = useState<Cliente[]>([]);

  useEffect(() => {
    if (entity) {
      const clientIds = new Set<number>();
      const allClientsUnique: Cliente[] = [];

      if (entity.clientsPrimary) {
        for (const client of entity.clientsPrimary) {
          if (!clientIds.has(client.id)) {
            clientIds.add(client.id);
            allClientsUnique.push(client);
          }
        }
      }

      if (entity.clients) {
        for (const client of entity.clients) {
          if (!clientIds.has(client.id)) {
            clientIds.add(client.id);
            allClientsUnique.push(client);
          }
        }
      }

      setAllClients(allClientsUnique);
    } else {
      setAllClients([]);
    }
  }, [entity]);

  const onLoadForm = async () => {};

  const onRetrieveEntity = async () => {
    // get vendedor
    const vendedorRes = await APIClient.get(`/agents/${id}`);

    setEntity(vendedorRes.data.data);

    return vendedorRes.data.data;
  };

  /**
   * Save the item
   */
  const onSaveEntity = async (entityToSave) => {
    let saveResponse: AxiosResponse;
    if (isAdding) {
      saveResponse = await APIClient.post('/agents', entityToSave);
    } else {
      saveResponse = await APIClient.patch(`/agents/${id}`, entityToSave);
    }

    navigate('/agents');
    addToast(`Vendedor ${saveResponse.data.data.id} guardado con éxito`, {
      appearance: 'success',
      autoDismiss: true,
    });
  };

  return (
    <div>
      <h1 className="page-title">
        {isAdding
          ? 'Ejecutivo comercial nuevo'
          : `Ejecutivo comercial ${entity?.erpCode ? `(Cod: ${entity.erpCode})` : `#${id}`}`}
      </h1>

      <EntityEditForm
        onLoadForm={onLoadForm}
        onRetrieveEntity={onRetrieveEntity}
        onSaveEntity={onSaveEntity}
        addMode={isAdding}>
        <>
          <Row>
            <Col md={6}>
              <FormInputField
                id="nombre"
                label="Nombre"
                as="input"
                defaultValue={entity?.nombre}
                disabled={true}
              />
            </Col>
            <Col md={6}>
              <FormCheckField
                id="eliminadoFlag"
                label="Inactivo"
                defaultChecked={entity?.eliminadoFlag}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormInputField
                id="email"
                label="E-mail"
                as="input"
                type="email"
                defaultValue={entity?.email}
              />
            </Col>
            <Col md={6}>
              <FormInputField
                id="phoneNumber"
                label="Teléfono"
                as="input"
                type="tel"
                defaultValue={entity?.phoneNumber}
              />
            </Col>
          </Row>
        </>
      </EntityEditForm>

      {allClients.length > 0 && (
        <Card className="mt-4">
          <Card.Body>
            <Card.Title>Clientes asignados</Card.Title>
            <Table bordered striped>
              <thead>
                <tr>
                  <th>Código</th>
                  <th>Nombre</th>
                  <th>Zona</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody>
                {allClients.map((client) => (
                  <tr>
                    <td>{client.erpCode}</td>
                    <td>{client.razonSocial}</td>
                    <td>{client.zona?.descripcion}</td>
                    <td>
                      {client.eliminadoFlag ? (
                        <Badge bg="danger">Inactivo</Badge>
                      ) : (
                        <Badge bg="success">Activo</Badge>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      )}
    </div>
  );
}
