import React from 'react';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveAs } from 'file-saver';
import { Button } from 'react-bootstrap';
import APIClient from '../services/APIClient';
import {
  generateWorkbookObject,
  s2ab,
  CSVToArrays,
  parseDataToExport,
  getNowDateLocale,
} from './utils';
import { useToasts } from 'react-toast-notifications';
import config from '../config';
import { DataTableExportCSVButtonProps } from '../types/dataTable';

function DataTableExportCSVButton({
  onGetExportData,
  propsTP,
  exportCustomHeaders,
  exportExtraFields,
  exportFileName,
  exportConfig,
  exportCols,
  disabled,
}: DataTableExportCSVButtonProps) {
  const { addToast } = useToasts();
  const handleClick = async () => {
    try {
      let formatedDataToExport;
      let filename;
      if (exportConfig) {
        // Convert the object to a JSON string and encode it
        const exportConfigQuery = encodeURIComponent(JSON.stringify(exportConfig));

        // Build the URL with the exportParseSettings query parameter
        const queryString = exportConfigQuery ? `?exportConfig=${exportConfigQuery}` : '';

        // Realizar la solicitud
        const response = await APIClient.get(`${exportConfig.exportURL}${queryString}`);
        if (response.data.data && typeof response.data.data !== 'string') {
          filename = `${exportFileName}_${getNowDateLocale().replaceAll('/', '-')}.${config.export.extension}`;
          formatedDataToExport = await parseDataToExport(
            response.data.data,
            exportCols,
            exportExtraFields,
            exportCustomHeaders,
          );
        } else if (response.data && typeof response.data === 'string') {
          filename = response.headers['content-disposition'].split(';')[1].substr(10);
          formatedDataToExport = await CSVToArrays(response.data);
        }
      } else if (onGetExportData) {
        filename = `${exportFileName}_${getNowDateLocale().replaceAll('/', '-')}.${config.export.extension}`;
        const exportData = await onGetExportData();
        formatedDataToExport = await parseDataToExport(
          exportData,
          exportCols,
          exportExtraFields,
          exportCustomHeaders,
        );
      } else {
        propsTP.csvProps.onExport();
        return;
      }
      const wbout = await generateWorkbookObject(formatedDataToExport);
      const s2abOut = await s2ab(wbout);
      const blobObject = new Blob([s2abOut], { type: 'application/octet-stream' });
      saveAs(blobObject, filename);
      addToast(`Reporte ${filename} descargado correctamente.`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      console.error('Error descargando el reporte.', err);
      addToast('Error descargando el reporte.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  return (
    <div>
      <Button
        className="d-flex align-items-center justify-content-center"
        variant="primary"
        onClick={handleClick}
        disabled={disabled}>
        <FontAwesomeIcon icon={faFileExcel} fixedWidth />
        <span>Exportar a EXCEL</span>
      </Button>
    </div>
  );
}

export default DataTableExportCSVButton;
