export enum SyncState {
  InProcess = 'in_process',
  Success = 'success',
  Error = 'error',
  PartialSuccess = 'partial_success',
}

export enum StatusCode {
  nonDelivered = 'NE',
  draft = 'SA',
  cancelled = 'SB',
  delivered = 'SC',
  confirmed = 'SE',
  inPreparation = 'SF',
  invoiced = 'IV',
  deliveredPartial = 'SP',
  pending = 'PC',
}

export enum OrderMisc {
  prefijo = 'MR',
  marca = 1,
  detalleTalleNombre = 'ST',
}

export enum InputMode {
  batch = 'colectivo',
  individual = 'individual',
}

export enum CodeRelation {
  pendiente = 'LIMPEND',
}

export enum DownloadFileType {
  audio = 'audio',
  csv = 'csv',
  document = 'document',
  spreadsheet = 'spreadsheet',
  word = 'word',
  image = 'image',
  pdf = 'pdf',
  text = 'text',
}

//#region ordertob-core ------------------------

export enum SourceChannel {
  integration = 'integration',
  backOffice = 'back_office',
  portalWeb = 'portal_web',
  whatsapp = 'whatsapp',
  email = 'email',
  api = 'api',
}

export enum SenderType {
  user = 'user',
  ordertob = 'ordertob',
}

export enum ConversationMessageContentType {
  audio = 'audio',
  document = 'document',
  text = 'text',
}

// ordertob-core
export enum ConversationStatus {
  initiated = 'initiated',
  inProgress = 'in_progress',
  finishedOrder = 'finished_order',
  finishedManual = 'finished_manual',
  finishedClient = 'finished_client',
  finishedErrorWhatsapp = 'finished_error_whatsapp',
  expired = 'expired',
}

export enum ConversationMessageStatus {
  pending = 'pending',
  sent = 'sent',
  received = 'received',
  read = 'read',
  error = 'error',
}

export declare enum MetaSupportedLanguage {
  Afrikaans = 'af',
  Albanian = 'sq',
  Amharic = 'am',
  Arabic = 'ar',
  Armenian = 'hy',
  Azerbaijani = 'az',
  Bangla = 'bn',
  Belarusian = 'be',
  Bosnian = 'bs',
  Bulgarian = 'bg',
  Catalan = 'ca',
  Chinese_Simplified = 'zh_CN',
  Chinese_Traditional = 'zh_TW',
  Croatian = 'hr',
  Czech = 'cs',
  Danish = 'da',
  Dutch = 'nl',
  English = 'en',
  Estonian = 'et',
  Finnish = 'fi',
  French = 'fr',
  Georgian = 'ka',
  German = 'de',
  Greek = 'el',
  Gujarati = 'gu',
  Hebrew = 'he',
  Hindi = 'hi',
  Hungarian = 'hu',
  Icelandic = 'is',
  Indonesian = 'id',
  Italian = 'it',
  Japanese = 'ja',
  Kannada = 'kn',
  Kazakh = 'kk',
  Khmer = 'km',
  Korean = 'ko',
  Kurdish = 'ku',
  Lao = 'lo',
  Latvian = 'lv',
  Lithuanian = 'lt',
  Macedonian = 'mk',
  Malay = 'ms',
  Malayalam = 'ml',
  Marathi = 'mr',
  Mongolian = 'mn',
  Nepali = 'ne',
  Norwegian_Bokmal = 'nb',
  Norwegian_Nynorsk = 'nn',
  Oriya = 'or',
  Pashto = 'ps',
  Persian = 'fa',
  Polish = 'pl',
  Portuguese = 'pt',
  Punjabi = 'pa',
  Romanian = 'ro',
  Russian = 'ru',
  Serbian = 'sr',
  Sinhala = 'si',
  Slovak = 'sk',
  Slovenian = 'sl',
  Somali = 'so',
  Spanish = 'es',
  Swahili = 'sw',
  Swedish = 'sv',
  Tagalog = 'tl',
  Tamil = 'ta',
  Telugu = 'te',
  Thai = 'th',
  Turkish = 'tr',
  Ukrainian = 'uk',
  Urdu = 'ur',
  Uzbek = 'uz',
  Vietnamese = 'vi',
  Welsh = 'cy',
  Xhosa = 'xh',
  Yoruba = 'yo',
  Zulu = 'zu',
}
export declare enum MetaMessageTemplateCategory {
  Authentication = 'AUTHENTICATION',
  Marketing = 'MARKETING',
  Utility = 'UTILITY',
}
export declare enum MetaMessageTemplateStatus {
  Approved = 'APPROVED',
  InAppeal = 'IN_APPEAL',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  PendingDeletion = 'PENDING_DELETION',
  Deleted = 'DELETED',
  Disabled = 'DISABLED',
  Paused = 'PAUSED',
  LimitExceeded = 'LIMIT_EXCEEDED',
}
export declare enum MetaMessageComponentType {
  Header = 'HEADER',
  Body = 'BODY',
  Footer = 'FOOTER',
  Buttons = 'BUTTONS',
}
export declare enum MetaMessageComponentFormat {
  Text = 'TEXT',
  Image = 'IMAGE',
  Audio = 'AUDIO',
  Document = 'DOCUMENT',
}

//#endregion ordertob-core ------------------------

/* eslint-disable no-useless-escape */
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const PHONE_REGEX =
  /^(?:(?:\(?(?:00|\+)([1-4]\d\d|[1-9]\d*)\)?)[\-\.\ \\\/]?)?((?:\(?\d{1,}\)?[\-\.\ \\\/]?)+)(?:[\-\.\ \\\/]?(?:#|ext\.?|extension|x)[\-\.\ \\\/]?(\d+))?$/i;
/* eslint-enable no-useless-escape */
