import React from 'react';
import moment from 'moment';
import config from '../config';
import { SourceChannel } from '../constants';

function sanitizeQuery(expectedFields, queryString) {
  const response = {
    page: '',
  };
  const querySections = queryString.split('&');
  if (querySections[0] === '') {
    expectedFields.forEach((field) => {
      response[field] = undefined;
    });
  } else {
    if (querySections[0].indexOf('?') >= 0) {
      querySections[0] = querySections[0].replace('?', '');
    }
    expectedFields.forEach((field) => {
      querySections.forEach((sec) => {
        if (sec.includes(field) && sec.includes('=')) {
          const querys = sec.split('=');
          if (querys[0] === field) {
            const value = querys[1];
            response[field] = value;
          } else {
            response[field] = undefined;
          }
        } else {
          response[field] = undefined;
        }
      });
    });
  }
  return response;
}

function prepareQueryToFilter(queryValueField, op, ...value) {
  if (value[0].length === 0 || value[0][0] === '') {
    return undefined;
  }
  let query = '';
  value.forEach((val) => {
    query += val.reduce((accum, val_, k, t) => {
      if (t[k] !== '') {
        return `${accum}filter[${queryValueField}][${op}]=${t[k]}&`;
      }
      return accum;
    }, '');
  });

  if (query) {
    // remove the last "&"
    return query.slice(0, query.length - 1);
  }
}

function stringToBoolean(aString) {
  let returnBoolean = false;
  switch (aString.toLowerCase()) {
    case 'true':
      returnBoolean = true;
      break;
    default:
      break;
  }
  return returnBoolean;
}

function isPositiveInteger(value) {
  return !Number.isNaN(value) && value >= 0 && Number.isInteger(value);
}

const forSplitProducts = (productsOfBody, tope) => {
  let subindiceEnArray;
  let cantidadATransladar;
  let count = 0;
  let thisBreak = false;
  for (let i = 0; i < productsOfBody.length; i += 1) {
    const product = productsOfBody[i];
    const precio = parseFloat(product.precioconivaincluido);
    for (let y = 1; y <= product.cantidad; y += 1) {
      count += precio;
      if (count >= tope) {
        subindiceEnArray = i;
        cantidadATransladar = y - 1;
        // frontera de array
        // if (cantidadATransladar === 0) {
        //   subindiceEnArray = i - 1;
        // }
        thisBreak = true;
        break;
      } else {
        subindiceEnArray = i;
        cantidadATransladar = y;
      }
    }
    if (thisBreak) {
      break;
    }
  }
  return { subindiceEnArray, cantidadATransladar };
};

async function splitProducts(productsOfBody, tope) {
  const cantYSubindice = await forSplitProducts(productsOfBody, tope);
  const { subindiceEnArray } = cantYSubindice;
  const { cantidadATransladar } = cantYSubindice;
  let seQuedan;
  let aTransladar;
  // product de corte
  const productBreak = JSON.parse(JSON.stringify(productsOfBody[subindiceEnArray]));
  const productBreak2 = JSON.parse(JSON.stringify(productsOfBody[subindiceEnArray]));
  const productBreakCantidadToKeep = productBreak.cantidad - cantidadATransladar;
  const allTransladar = productBreakCantidadToKeep === 0;
  const allSeQuedan = productBreakCantidadToKeep === productBreak.cantidad;
  const quedanYTransladan = productBreakCantidadToKeep > 0 && !allSeQuedan;
  if (allTransladar) {
    seQuedan = productsOfBody.splice(subindiceEnArray + 1);
    aTransladar = productsOfBody;
  } else if (allSeQuedan) {
    seQuedan = productsOfBody.splice(subindiceEnArray);
    aTransladar = productsOfBody;
  } else if (quedanYTransladan) {
    seQuedan = productsOfBody.splice(subindiceEnArray + 1);
    productBreak.cantidad = productBreakCantidadToKeep;
    seQuedan.unshift(productBreak);

    aTransladar = productsOfBody;
    productBreak2.cantidad = cantidadATransladar;
    aTransladar[aTransladar.length - 1].cantidad = cantidadATransladar;
  }
  return { aTransladar, seQuedan };
}

export const buildDateAgo = (days) => {
  const currentDate = new Date();
  const miliseconds = days * 24 * 60 * 60 * 1000;
  const dateAgo = new Date(currentDate.getTime() - miliseconds);
  const parseResultDate = moment.utc(dateAgo).format('YYYY-MM-DD').toString();
  return parseResultDate;
};

export function formatAmount(number) {
  let formattedNumber = '';
  if (number) {
    const currencySymbol = config.numberFormat.currency;
    formattedNumber = `${currencySymbol} ${Intl.NumberFormat(config.numberFormat.locale, {
      style: 'decimal',
    }).format(number)}`;
  }
  return formattedNumber;
}

export function renderSourceChannel(aiExecutionMedium) {
  let sourceChannel;
  switch (aiExecutionMedium) {
    case SourceChannel.backOffice:
      sourceChannel = 'Central de Pedidos';
      break;
    case SourceChannel.portalWeb:
      sourceChannel = 'Portal Web';
      break;
    case SourceChannel.whatsapp:
      sourceChannel = 'WhatsApp';
      break;
    case SourceChannel.api:
      sourceChannel = 'API';
      break;
    case SourceChannel.integration:
      sourceChannel = 'Integración';
      break;
    case SourceChannel.email:
      sourceChannel = 'E-mail';
      break;
    default:
      sourceChannel = '';
      break;
  }
  return <span>{sourceChannel}</span>;
}

export function generateDeliveryString(deliveryPoint) {
  if (deliveryPoint == null) {
    return undefined;
  }

  return `${deliveryPoint.descripcion} - ${deliveryPoint.calle}${
    deliveryPoint.numero !== '00' ? ` ${deliveryPoint.numero}` : ''
  }${deliveryPoint.piso ? ` ${deliveryPoint.piso}` : ''}${
    deliveryPoint.depto ? ` ${deliveryPoint.depto}` : ''
  } ${deliveryPoint.localidad}${
    deliveryPoint.codigoPostal && deliveryPoint.codigoPostal !== '0'
      ? ` cp: ${deliveryPoint.codigoPostal}`
      : ''
  }${deliveryPoint.provincia ? ` ${deliveryPoint.provincia.descripcion}` : ''}`;
}

export const compareUserRoles = (userRoles, rol) => {
  const roleExists = userRoles.find((e) => e.id === rol.id);
  return roleExists;
};

export default {
  sanitizeQuery,
  prepareQueryToFilter,
  stringToBoolean,
  isPositiveInteger,
  splitProducts,
  formatAmount,
  renderSourceChannel,
  generateDeliveryString,
};
