import React, { ChangeEventHandler, ElementType } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic } from '@fortawesome/free-solid-svg-icons';

interface FormInputFieldProps {
  id: string;
  label: string;
  as?: ElementType;
  type?:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
  placeholder?: string;
  defaultValue?: string | number | readonly string[] | null;
  value?: string | number | string[] | null;
  style?: object | any;
  disabled?: boolean;
  required?: boolean;
  arrayvalidateindex?: string[];
  isInvalid?: boolean;
  validText?: string;
  invalidText?: string;
  min?: string;
  max?: string;
  onChange?: ChangeEventHandler;
  aiStatusIconColor?: string;
  aiInterpretDescription?: string;
}

export default function FormInputField({
  validText,
  arrayvalidateindex = [],
  invalidText,
  id,
  label,
  isInvalid = false,
  defaultValue,
  value,
  as = 'input',
  disabled = false,
  required = false,
  type,
  min,
  max,
  aiStatusIconColor,
  aiInterpretDescription,

  ...otherProps
}: FormInputFieldProps) {
  const aiTooltip = <Tooltip>{aiInterpretDescription}</Tooltip>;

  return (
    <Form.Group className="mt-3" controlId={id}>
      <Form.Label>{label}</Form.Label>
      <div className="d-flex align-items-center justify-content-between">
        <Form.Control
          name={id}
          as={as}
          type={type}
          min={min}
          max={max}
          required={required}
          disabled={disabled}
          defaultValue={defaultValue == null ? undefined : defaultValue}
          value={value == null ? undefined : value}
          {...otherProps}
        />
        {aiInterpretDescription && (
          <OverlayTrigger overlay={aiTooltip}>
            <div className="ms-2">
              <FontAwesomeIcon icon={faMagic} color={aiStatusIconColor} fixedWidth />
            </div>
          </OverlayTrigger>
        )}
      </div>
      {validText && <Form.Control.Feedback type="valid">{validText}</Form.Control.Feedback>}
      {invalidText && <Form.Control.Feedback type="invalid">{invalidText}</Form.Control.Feedback>}
    </Form.Group>
  );
}
