import React, { useEffect, useState } from 'react';
import {
  faChevronLeft,
  faChevronRight,
  faL,
  faPaperPlane,
  faSpinner,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  Badge,
  Button,
  Col,
  Dropdown,
  DropdownButton,
  Form,
  FormGroup,
  InputGroup,
  Modal,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import { Cliente, Contact, WAMessageTemplate, WAMessageTemplateComponent } from '../../types/model';
import { DataTable, FormCheckField, FormSelectCustom, FormSelectField } from '../../components';
import APIClient from '../../services/APIClient';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { SelectFieldChangeEvent } from '../../components/FormSelectField';
import './sendMessagesModal.scss';
import { buildListEndpointQueryParams, QueryParameters } from '../UIUtils';
import { useToasts } from 'react-toast-notifications';
import { SelectCustomEventArg, SelectCustomOption } from '../../components/FormSelectCustom';
import { DataTableColumnProps } from '../../types/dataTable';
import { MessageTemplateParsedParameters } from '../../types/whatsapp';
import { MetaMessageComponentType } from '../../constants';

//#region Auxiliar types
enum ChannelTabs {
  WhatsApp = 'whatsapp',
  Email = 'email',
}
enum WizardSteps {
  SelectChannelAndTemplate = 1,
  FilterContacts = 2,
  SelectContacts = 3,
  Confirm = 4,
}

enum MessageDynamicVariable {
  contactFirstName = 'contact.firstName',
  contactLastName = 'contact.lastName',
}

type ContactFilters = {
  clientId?: number;
};

//#endregion Auxiliar types

interface SendMessagesModalProps {
  show: boolean;
  onHide?: Function;
}
export default function SendMessagesModal({ show, onHide }: SendMessagesModalProps) {
  const { addToast } = useToasts();

  const [isShown, setShown] = useState<boolean>(show);
  const [currentStep, setCurrentStep] = useState<WizardSteps>(WizardSteps.SelectChannelAndTemplate);
  const [messageTempates, setMessageTemplates] = useState<WAMessageTemplate[]>([]);
  const [selectedMessageTemplate, setSelectedMessageTemplate] = useState<WAMessageTemplate | null>(
    null,
  );
  const [componentParams, setComponentParams] = useState<{ id: number; params: string[] }[]>([]);

  const [contactFilters, setContactFilters] = useState<ContactFilters>({});
  const [selectedClientLabel, setSelectedClientLabel] = useState<string | undefined>();
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [isContactsDataLoading, setIsContactsDataLoading] = useState<boolean>(false);
  const [contactsTotalSize, setContactsTotalSize] = useState<number>(0);
  const [selectedContacts, setSelectedContacts] = useState<Contact[]>([]);

  const [sendMessageNow, setSendMessageNow] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);

  const [feedbackMessage, setFeedbackMessage] = useState<string | undefined>();

  useEffect(() => {
    // if open when it was closed, reset the wizard step
    if (show && !isShown) {
      setCurrentStep(WizardSteps.SelectChannelAndTemplate);
    }
    setShown(show);

    // load additional data
    if (show) {
      loadMessageTemplates();
    }
  }, [show]);

  function closeModal() {
    setShown(false);
    if (onHide) {
      onHide();
    }
  }

  async function loadMessageTemplates() {
    const templates = await APIClient.get<WAMessageTemplate[]>(
      '/conversations/whatsapp/message-templates',
    );
    setMessageTemplates(templates.data.data);
  }

  function generateTemplateContentForDisplay(component: WAMessageTemplateComponent) {
    const textParts = component.text?.split(/({{\d+}}|\\n)/g) ?? [];
    const reactElements = textParts.map((part, index) => {
      if (part.startsWith('{{') && part.endsWith('}}')) {
        return (
          <Badge key={index} bg="secondary">
            {part}
          </Badge>
        );
      }
      if (part === '\\n') {
        return <br key={index} />;
      }
      return <React.Fragment key={index}>{part}</React.Fragment>;
    });
    return <>{reactElements}</>;
  }

  function renderMultiselectionActions() {
    return (
      <div className="d-flex px-2 py-1 bg-white border rounded">
        Contactos seleccionados:
        <strong className="ms-1">
          {selectedContacts?.length
            ? selectedContacts?.length
            : `${filteredContacts.length} (todos)`}
        </strong>
      </div>
    );
  }

  function getDynamicVariableLabel(dynamicVariable: MessageDynamicVariable) {
    switch (dynamicVariable) {
      case MessageDynamicVariable.contactFirstName:
        return 'Contacto - nombre';
      case MessageDynamicVariable.contactLastName:
        return 'Contacto - apellido';
      default:
        return dynamicVariable;
    }
  }

  //#region Event handlers >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  function handleHide() {
    setShown(false);
    if (onHide) onHide();
  }

  function handleCloseClick() {
    setShown(false);
    if (onHide) onHide();
  }

  function handleContinueClick() {
    switch (currentStep) {
      case WizardSteps.SelectChannelAndTemplate: {
        // first validate if there's a template selected
        if (!selectedMessageTemplate) {
          setFeedbackMessage('Debes seleccionar una plantilla.');
          return;
        }
        // validate that, if there are parameters, all of them have values
        const allParamsHaveValues = componentParams.every((cp) => cp.params.every((p) => p));
        if (!allParamsHaveValues) {
          setFeedbackMessage('Debes ingresar valores para todos los parámetros de la plantilla.');
          // add class to inputs that were not empty
          const inputs = document.querySelectorAll<HTMLFormElement>('.parameter-input');
          inputs.forEach((input) => {
            if (input.value) {
              input.classList.remove('is-invalid');
            } else {
              input.classList.add('is-invalid');
            }
          });
          return;
        }

        // everything ok, go to next step
        setFeedbackMessage(undefined);
        setCurrentStep(WizardSteps.FilterContacts);
        break;
      }
      case WizardSteps.FilterContacts: {
        setCurrentStep(WizardSteps.SelectContacts);
        break;
      }
      case WizardSteps.SelectContacts: {
        setCurrentStep(WizardSteps.Confirm);
        break;
      }
      default:
        // no other step possible
        console.warn('No other step possible');
        break;
    }
  }

  function handleBackClick() {
    switch (currentStep) {
      case WizardSteps.FilterContacts:
        setCurrentStep(WizardSteps.SelectChannelAndTemplate);
        break;
      case WizardSteps.SelectContacts:
        setCurrentStep(WizardSteps.FilterContacts);
        break;
      case WizardSteps.Confirm:
        setCurrentStep(WizardSteps.SelectContacts);
        break;
      default:
        // no other step possible
        console.warn('No other step possible');
        break;
    }
  }

  async function handleSendClick() {
    if (isSending) return;

    setIsSending(true);

    // validate first
    if (!selectedMessageTemplate) {
      setFeedbackMessage('Debes seleccionar una plantilla.');
      return;
    }
    if (!selectedContacts?.length && !filteredContacts?.length) {
      setFeedbackMessage('Debes seleccionar al menos un contacto.');
      return;
    }
    setFeedbackMessage(undefined);

    // Start preparing the request body
    let requestBody: {
      contacts: {
        ids?: number[];
        phoneNumbers?: string[];
        filters?: Record<string, any>;
      };
      waMessageTempateId: number;
      waMessageTemplateParameters?: MessageTemplateParsedParameters;
      sendImmediately?: boolean;
    } = {
      contacts: {},
      waMessageTempateId: selectedMessageTemplate.id,
      waMessageTemplateParameters: {},
      sendImmediately: sendMessageNow,
    };

    // prepare the contacts
    if (selectedContacts?.length) {
      // if specific contacts were selected, set them
      requestBody.contacts = { ids: selectedContacts.map((c) => c.id) };
    } else {
      // if no specific contacts were selected, set the filters
      requestBody.contacts = {
        filters: {
          clientId: contactFilters.clientId,
        },
      };
    }

    // prepare the params to send to the API
    if (componentParams?.length) {
      // Map params to component type
      // The params need to comply with certain structure that separates them by Component Type (Header, Body, etc.)
      requestBody.waMessageTemplateParameters = componentParams.reduce((prev, curr) => {
        const foundComponent = selectedMessageTemplate.components?.find((c) => c.id === curr.id);
        if (!foundComponent) {
          return {
            ...prev,
          };
        }

        return {
          ...prev,
          // conver the param values as string into the param object, assuming all type "text"
          [foundComponent?.type]: curr.params.map((paramValue) => ({
            type: 'text',
            text: paramValue,
          })),
        };
      }, {} as MessageTemplateParsedParameters);
    }

    try {
      const scheduleResult = await APIClient.post(
        '/conversations/whatsapp/send-template',
        requestBody,
      );
      console.debug('Scheduled outgoing messages', scheduleResult);

      addToast('Mensajes programados con éxito', {
        appearance: 'success',
      });
      closeModal();
    } catch (error) {
      console.error('Error scheduling outgoing messages', error);
      setFeedbackMessage('Error preparando mensajes para enviar.');
    } finally {
      setIsSending(false);
    }
  }

  function handleMessageTemplateChange(e: SelectFieldChangeEvent): void {
    const selectedId = e.target.value;
    let selectedTemplate: WAMessageTemplate | null = null;
    let params: typeof componentParams = [];

    if (selectedId) {
      const foundTemplate = messageTempates.find((t) => t.id === parseInt(selectedId, 10));
      if (foundTemplate) {
        selectedTemplate = foundTemplate;
        // prepare the params based on the component, assign '' as default for each
        params =
          selectedTemplate.components?.map((c) => ({
            id: c.id,
            params: c.text?.match(/({{\d+}})/g)?.map((m) => '') ?? [],
          })) ?? [];
      }
    }
    setSelectedMessageTemplate(selectedTemplate);
    setComponentParams(params);
  }

  function handleParamTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    const componentId = e.target.getAttribute('data-component-id');
    const paramIndex = e.target.getAttribute('data-param-index');

    if (componentId && paramIndex) {
      const componentParamsEntry = componentParams.find((c) => c.id === parseInt(componentId, 10));
      if (componentParamsEntry) {
        // update that specific param
        setComponentParams(
          componentParams.map((cp) => {
            if (cp.id === parseInt(componentId, 10)) {
              return {
                ...cp,
                params: cp.params.map((p, i) => {
                  return i === parseInt(paramIndex, 10) ? e.target.value : p;
                }),
              };
            }
            return cp;
          }),
        );
      }
    }
  }

  function handleDynamicVariableClick(e: React.MouseEvent<HTMLAnchorElement>) {
    const componentId = e.currentTarget.getAttribute('data-component-id');
    const paramIndex = e.currentTarget.getAttribute('data-param-index');

    if (componentId && paramIndex) {
      const componentParamsEntry = componentParams.find((c) => c.id === parseInt(componentId, 10));
      if (componentParamsEntry) {
        const placeholderKeyToInsert = `{{${e.currentTarget.getAttribute(
          'data-placeholder-key',
        )}}}`;
        // update that specific param
        setComponentParams(
          componentParams.map((cp) => {
            if (cp.id === parseInt(componentId, 10)) {
              return {
                ...cp,
                params: cp.params.map((p, i) => {
                  return i === parseInt(paramIndex, 10) ? `${p}${placeholderKeyToInsert}` : p;
                }),
              };
            }
            return cp;
          }),
        );
      }
    }
  }

  function handleSendNowChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { checked } = e.target;
    setSendMessageNow(checked);
  }

  //#endregion Event handlers >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  //#region Contacts event handlers >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  async function handleClientCustomLoadOptionsHandler(
    query: string,
  ): Promise<SelectCustomOption[]> {
    const res = await APIClient.get(
      `/clientes?filter[razonSocial][like]=${encodeURIComponent(`%${query}%`)}`,
    );
    const dataFetched = res.data.data;
    const optionsFormatted = dataFetched.map((data: any) => ({
      value: data.id,
      label: data.razonSocial,
    }));
    return optionsFormatted;
  }

  function handleContactsFilterFieldChange(event: SelectCustomEventArg) {
    const { id, value, label } = event.target;

    switch (id) {
      case 'filterClientId': {
        setContactFilters({ ...contactFilters, clientId: value ? parseInt(value) : undefined });
        setSelectedClientLabel(label);
        break;
      }
      default:
        // do nothing
        console.warn(`Unexpected fitler input key: "${id}"`);
        break;
    }
  }

  async function handleContactsTableUpdate(queryParameters: QueryParameters) {
    setIsContactsDataLoading(true);
    try {
      // query params from the table
      let queryParams = buildListEndpointQueryParams(queryParameters);

      // query params from the filters
      const filtersQueryParams: string[] = [];
      if (contactFilters.clientId) {
        filtersQueryParams.push(`filter[clientId][eq]=${contactFilters.clientId}`);
      }
      queryParams = [queryParams, ...filtersQueryParams].join('&');

      const contacts = await APIClient.get<Contact[]>(`/clients/contacts?${queryParams}`);
      setFilteredContacts(contacts.data.data);
      setContactsTotalSize(contacts.data.meta.total);
      setSelectedContacts([]);
    } catch (error) {
      addToast(`Error obteniendo contactos. ${(error as Error).message}`, {
        appearance: 'error',
      });
    } finally {
      setIsContactsDataLoading(false);
    }
  }

  function handleContactRowSelect(row: any, isSelected: boolean) {
    setSelectedContacts((prevSelectedContacts) => {
      return isSelected
        ? [...prevSelectedContacts, row]
        : prevSelectedContacts.filter((selectedContact) => selectedContact.id !== row.id);
    });
  }

  function handleContactSelectAllRows(isSelect: boolean, rows: any[]) {}

  //#endregion Contacts event handlers >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  const contactsTableColumns: DataTableColumnProps[] = [
    {
      dataField: 'id',
      text: 'ID',
      sort: false,
      hidden: true,
    },
    {
      dataField: 'firstName',
      text: 'Nombre',
      sort: true,
    },
    {
      dataField: 'lastName',
      text: 'Apellido',
      sort: true,
    },
    {
      dataField: 'phoneNumber',
      text: 'Teléfono',
      sort: true,
      formatter: (cellContent: string, row) => {
        const cleanNumber = cellContent?.replace(/\D/g, '');
        return (
          <>
            {cellContent}
            {(!cellContent || cleanNumber.length < 8) && (
              <FontAwesomeIcon
                icon={faWarning}
                className="float-end text-warning"
                title="Número de teléfono no válido"
              />
            )}
          </>
        );
      },
    },
    {
      dataField: 'email',
      text: 'E-mail',
      sort: true,
      formatter: (cellContent: string, row) => {
        return (
          <>
            {cellContent}
            {(!cellContent || cellContent.indexOf('@') == -1) && (
              <FontAwesomeIcon
                icon={faWarning}
                className="float-end text-warning"
                title="E-mail no válido"
              />
            )}
          </>
        );
      },
    },
    {
      dataField: 'clients',
      text: 'Clientes',
      sort: false,
      formatter: (cellContent: Cliente[], row) => {
        return cellContent?.map((client) => client.razonSocial).join(', ') || '';
      },
    },
  ];

  return (
    <Modal size="xl" show={isShown} onHide={handleHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          {/* Object.values on this enum return double the elements */}
          Enviar mensajes - Paso {currentStep}/{Object.entries(WizardSteps).length / 2}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentStep == WizardSteps.SelectChannelAndTemplate && (
          <Tabs>
            <Tab
              eventKey={ChannelTabs.WhatsApp}
              title={
                <>
                  <FontAwesomeIcon icon={faWhatsapp} fixedWidth /> WhatsApp
                </>
              }>
              <p>
                Para poder enviar mensajes de WhatsApp es necesario utilizar alguno de las
                plantillas definidas previamente.
              </p>
              <FormSelectField
                id="messageTemplate"
                label="Selecciona la plantilla de mensaje a utilizar:"
                choices={messageTempates}
                choiceIdField="id"
                choiceLabelField="name"
                value={selectedMessageTemplate?.id?.toString()}
                onChange={handleMessageTemplateChange}
              />
              {selectedMessageTemplate && (
                <div className="mt-2 wa-message-template-content">
                  {selectedMessageTemplate.components?.map((c) => (
                    <div className="p-2 border bg-light" key={c.id}>
                      <p>{generateTemplateContentForDisplay(c)}</p>
                      <Row>
                        <Col xl={6}>
                          <hr />
                          {
                            //render the params inputs
                            componentParams
                              .find((cp) => cp.id === c.id)
                              ?.params.map((paramValue, index) => (
                                <InputGroup size="sm" className="mb-1" key={index}>
                                  <InputGroup.Text>
                                    Variable
                                    <Badge bg="secondary" className="ms-1">
                                      &#123;&#123;{index + 1}&#125;&#125;
                                    </Badge>
                                  </InputGroup.Text>
                                  <Form.Control
                                    type="input"
                                    name={`param-${c.id}-${index}`}
                                    className="parameter-input"
                                    value={paramValue}
                                    data-component-id={c.id}
                                    data-param-index={index}
                                    onChange={handleParamTextChange}
                                  />
                                  <DropdownButton
                                    variant="outline-secondary"
                                    title="Valores dinámicos"
                                    id={`dyamic-var-${c.id}-${index}`}
                                    align="end">
                                    {Object.values(MessageDynamicVariable).map((dynamicVarKey) => (
                                      <Dropdown.Item
                                        key={dynamicVarKey}
                                        data-placeholder-key={dynamicVarKey}
                                        data-component-id={c.id}
                                        data-param-index={index}
                                        href="#"
                                        onClick={handleDynamicVariableClick}>
                                        {getDynamicVariableLabel(dynamicVarKey)}
                                      </Dropdown.Item>
                                    ))}
                                  </DropdownButton>
                                </InputGroup>
                              ))
                          }
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              )}
            </Tab>
            <Tab
              eventKey={ChannelTabs.Email}
              disabled
              title={
                <>
                  <FontAwesomeIcon icon={faEnvelope} fixedWidth /> E-mail (próximamante)
                </>
              }>
              <p>No disponible</p>
            </Tab>
          </Tabs>
        )}
        {currentStep == WizardSteps.FilterContacts && (
          <>
            <p>Filtra los contactos a quienes enviar el mensaje:</p>
            <Row>
              <Col lg={6}>
                <FormSelectCustom
                  id="filterClientId"
                  label="Cliente"
                  onLoadOptions={handleClientCustomLoadOptionsHandler}
                  onChange={handleContactsFilterFieldChange}
                  selectedOption={
                    contactFilters.clientId
                      ? {
                          value: String(contactFilters.clientId),
                          label: selectedClientLabel ?? '-',
                        }
                      : undefined
                  }
                  clearable={true}
                />
              </Col>
            </Row>
          </>
        )}
        {currentStep == WizardSteps.SelectContacts && (
          <>
            <p>Revisa y/o selecciona los contactos a quienes enviar el mensaje:</p>

            <DataTable
              remote={{
                filter: true,
                pagination: true,
                sort: true,
                cellEdit: false,
              }}
              showSearch={false}
              columns={contactsTableColumns}
              data={filteredContacts}
              onTableUpdate={handleContactsTableUpdate}
              isDataLoading={isContactsDataLoading}
              totalSize={contactsTotalSize}
              keyField="id"
              // defaultSorted={[{ dataField: 'firstName', order: 'asc' }]}
              selectRow={{
                mode: 'checkbox',
                clickToSelect: true,
                hideSelectAll: true,
                hideSelectColumn: false,
                onSelect: handleContactRowSelect,
                onSelectAll: handleContactSelectAllRows,
              }}
              renderMultiSelectionActions={renderMultiselectionActions()}
            />
          </>
        )}
        {currentStep == WizardSteps.Confirm && (
          <>
            <p>
              Se enviará un mensaje de la plantilla <strong>{selectedMessageTemplate?.name}</strong>{' '}
              a:
            </p>
            <p className="fs-5">
              {selectedContacts.length > 0 ? selectedContacts.length : filteredContacts.length}{' '}
              contactos
            </p>
            <FormGroup>
              <FormCheckField
                id="send-now-check"
                label="Enviar mensajes inmediatamente"
                onChange={handleSendNowChange}
                defaultChecked={sendMessageNow}
              />
              <Form.Text id="send-now-check" muted>
                Si tildas esta casilla, los mensajes serán enviados inmediatamente. Sino, serán
                programados para enviarse en los envíos programados. <br />
                Es recomendable dejar que OrderToB decida el momento del envío de los mensajes.
              </Form.Text>
            </FormGroup>
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-between">
        <div className="flex-grow-1">
          {feedbackMessage && <p className="text-danger">{feedbackMessage}</p>}
        </div>
        <div>
          {currentStep == 1 ? (
            <Button variant="secondary" onClick={handleCloseClick} className="me-1">
              Cancelar
            </Button>
          ) : (
            <Button variant="secondary" onClick={handleBackClick} className="me-1">
              <FontAwesomeIcon icon={faChevronLeft} fixedWidth className="me-1" /> Atrás
            </Button>
          )}

          {/* Object.values on this enum return double the elements */}
          {currentStep < Object.values(WizardSteps).length / 2 ? (
            <Button variant="primary" onClick={handleContinueClick}>
              Siguiente <FontAwesomeIcon icon={faChevronRight} fixedWidth className="ms-1" />
            </Button>
          ) : (
            <Button variant="primary" onClick={handleSendClick} disabled={isSending}>
              {isSending ? (
                <>
                  Enviando... <FontAwesomeIcon icon={faSpinner} fixedWidth className="ms-1" spin />
                </>
              ) : (
                <>
                  Enviar <FontAwesomeIcon icon={faPaperPlane} fixedWidth className="ms-1" />
                </>
              )}
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
