import React from 'react';
import { Route } from 'react-router-dom';
import ConnectorDashboard from './ConnectorDashboard';
import ConnectorView from './ConnectorView';

const routes = () =>
  [
    <Route path="/connectors/:code" element={<ConnectorView />} key="/connectors/:code" />,
    <Route path="/connectors" element={<ConnectorDashboard />} key="/connectors" />,
  ].map((route) => route);

export default routes;
