import React, { MouseEventHandler, ReactElement, useEffect, useState } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { Button, Card, Col, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { LinkContainer } from 'react-router-bootstrap';
import DataTableSearchField from './DataTableSearchField';
import DataTableFooter from './DataTableFooter';
import Loading from './Loading';
import {
  DataTableColumnProps,
  DataTableExportColumnProps,
  DataTableRemoteProps,
  ExportConfig,
  PaginationProps,
} from '../types/dataTable';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import './DataTable.css';

interface CustomBootstrapTableProps {
  propsTP: any;
  paginationProps?: PaginationProps;
  paginationTableProps?: any;
  pageNumber?: number;
  addButton?: string | ReactElement | MouseEventHandler<HTMLButtonElement>;
  buttonText: string;
  buttonIcon?: IconProp;
  onSecondButtonClick?: string | ReactElement | MouseEventHandler<HTMLButtonElement>;
  secondButtonText?: string;
  secondButtonIcon?: IconProp;
  secondButtonVariant?: string;
  defaultSorted?: Array<SortParam>;
  isDataLoading: boolean;
  remote?: DataTableRemoteProps;
  showSearch?: boolean;
  onTableRef?: (ref: BootstrapTable) => void;
  onTableUpdate?: (queryParams: any) => Promise<void>;
  customHeaders?: string[];
  exportConfig?: ExportConfig;
  getExportData?: () => void;
  exportExtraFields?: string[];
  exportFileName?: string;
  exportCols?: DataTableExportColumnProps[];
  renderMultiSelectionActions?: ReactElement;
  selectRow?: SelectRowProps;
  selectedRowIds?: string[];
  updateRowField?: (row: any, column?: any, newValue?: any) => void;
}
function CustomBootstrapTable({
  propsTP,
  paginationProps,
  paginationTableProps = {},
  pageNumber,
  addButton,
  onSecondButtonClick,
  buttonText,
  buttonIcon,
  secondButtonText,
  secondButtonIcon,
  secondButtonVariant = 'success',
  defaultSorted = [],
  remote,
  isDataLoading,
  showSearch = true,
  onTableRef = () => {},
  onTableUpdate,
  exportConfig,
  getExportData,
  customHeaders,
  exportExtraFields,
  exportFileName,
  exportCols,
  renderMultiSelectionActions,
  selectRow,
  selectedRowIds = [],
  updateRowField,
  ...otherProps
}: CustomBootstrapTableProps) {
  const [sortField, setSortField] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<string>('');
  const [page, setPage] = useState<number>(pageNumber || 1);
  const [sizePerPage, setSizePerPage] = useState<number>(10);
  const [searchText, setSearchText] = useState<string>('');

  /**
   * receives table changes, generates parameters for query, saves changes to
   * state and calls onTableUpdate function.
   * @param  {string} type - change type
   * @param  {number} page - page number
   * @param  {number} sizePerPage - current page size
   * @param  {string} searchText - current text in search bar
   * @param  {string} sortField - current field to sort by
   * @param  {string} sortOrder - current order to sort by
   */
  useEffect(() => {
    handleTableChange('pagination', {
      sortField,
      sortOrder,
      page,
      sizePerPage,
      searchText,
    });
  }, []);

  async function handleTableChange(
    type,
    {
      sortField: sortFieldArg,
      sortOrder: sortOrderArg,
      page: pageArg,
      sizePerPage: sizePerPageArg,
      searchText: searchTextArg,
    },
  ) {
    const sorting: {
      field?: string;
      direction?: 'ASC' | 'DESC';
    } = {};
    const queryParameters: {
      pagination?: {
        limit: number;
        offset: number;
        page: number;
      };
      freeText?: string;
      sorting?: typeof sorting;
    } = {};

    queryParameters.pagination = {
      limit: sizePerPageArg,
      offset: pageArg === 0 ? 0 : (pageArg - 1) * sizePerPageArg,
      page: pageArg,
    };
    if (searchTextArg && searchTextArg.length > 0) {
      queryParameters.freeText = searchTextArg;
    } else {
      queryParameters.freeText = '';
    }
    if (sortFieldArg && sortFieldArg.length > 0 && sortOrderArg && sortOrderArg.length > 0) {
      sorting.field = sortFieldArg;
      sorting.direction = sortOrderArg.toUpperCase() as 'ASC' | 'DESC';
    }
    queryParameters.sorting = sorting;

    setPage(type === 'search' ? 1 : pageArg);
    setSizePerPage(sizePerPageArg);
    setSortField(!sortFieldArg ? sortField : sortFieldArg);
    setSortOrder(!sortOrderArg ? sortOrder : sortOrderArg);
    if (onTableUpdate) {
      await onTableUpdate(queryParameters);
    }
  }

  const selectRowConfig = selectRow;

  if (paginationProps && remote) {
    paginationProps.page = page;
    paginationProps.sizePerPage = sizePerPage;
  }
  const cellEdit = {
    mode: 'dbclick',
    afterSaveCell: (oldValue, newValue, row, column) => {
      if (updateRowField) updateRowField(row, column, newValue);
    },
  };

  return (
    <Card>
      <Card.Header>
        <Row>
          <Col md={12} className="d-flex justify-content-between">
            {addButton && typeof addButton === 'string' ? (
              <LinkContainer to={addButton} className="mb-2">
                <Button variant="primary" size="sm">
                  <FontAwesomeIcon className="me-1" icon={buttonIcon || faPlus} fixedWidth />
                  {buttonText}
                </Button>
              </LinkContainer>
            ) : (
              addButton && (
                <Button
                  variant="primary"
                  onClick={addButton as MouseEventHandler<HTMLButtonElement>}
                  size="sm">
                  <FontAwesomeIcon className="me-1" icon={buttonIcon || faPlus} fixedWidth />
                  {buttonText}
                </Button>
              )
            )}

            {onSecondButtonClick && typeof onSecondButtonClick === 'string' ? (
              <LinkContainer to={onSecondButtonClick} className="ms-2">
                <Button variant={secondButtonVariant || 'success'} size="sm">
                  <FontAwesomeIcon className="me-1" icon={secondButtonIcon || faPlus} fixedWidth />
                  {secondButtonText}
                </Button>
              </LinkContainer>
            ) : (
              onSecondButtonClick && (
                <Button
                  variant={secondButtonVariant || 'success'}
                  onClick={onSecondButtonClick as MouseEventHandler<HTMLButtonElement>}
                  size="sm"
                  className="ms-2">
                  <FontAwesomeIcon className="me-1" icon={secondButtonIcon || faPlus} fixedWidth />
                  {secondButtonText}
                </Button>
              )
            )}
            {renderMultiSelectionActions}
          </Col>
          {!showSearch ? null : (
            <Col md={6}>
              <DataTableSearchField {...propsTP.searchProps} />
            </Col>
          )}
        </Row>
      </Card.Header>

      <BootstrapTable
        ref={(n) => onTableRef && onTableRef(n)}
        bootstrap4
        striped
        hover
        condensed
        responsive
        wrapperClasses="table-responsive"
        remote={remote ? { ...remote } : false}
        defaultSorted={defaultSorted}
        onTableChange={handleTableChange}
        selectRow={selectRowConfig}
        {...paginationTableProps}
        loading={isDataLoading}
        noDataIndication={isDataLoading ? <Loading /> : <p>No hay información</p>}
        cellEdit={updateRowField ? cellEditFactory(cellEdit) : cellEditFactory()}
        {...propsTP.baseProps}
        {...otherProps}
      />

      <DataTableFooter
        paginationProps={paginationProps}
        propsTP={propsTP}
        getExportData={getExportData}
        exportConfig={exportConfig}
        exportExtraFields={exportExtraFields}
        exportCustomHeaders={customHeaders}
        exportFileName={exportFileName}
        exportCols={exportCols}
        {...otherProps}
      />
    </Card>
  );
}

export interface DataTableProps {
  getExportData?: () => Promise<any[]>;
  rowClasses?: string | any;
  columns: DataTableColumnProps[];
  cellEdit?: CellEditProps;
  selectRow?: SelectRowProps;
  pageNumber?: number;
  onTableUpdate?: (queryParams: any) => Promise<void>;
  totalSize?: number;
  remote?: DataTableRemoteProps;
  keyField: string;
  data: any[];
  defaultSorted?: SortParam[];
  addButton?: string | ReactElement | MouseEventHandler<HTMLButtonElement>;
  buttonText?: string;
  buttonIcon?: ReactElement | any;
  onSecondButtonClick?: string | ReactElement | MouseEventHandler<HTMLButtonElement>;
  secondButtonText?: string;
  secondButtonIcon?: ReactElement | any;
  secondButtonVariant?: string;
  isDataLoading: boolean;
  showSearch?: boolean;
  enablePagination?: boolean;
  exportFileName?: string;
  exportExtraFields?: string[];
  exportCols?: DataTableExportColumnProps[];
  customHeaders?: string[];
  updateRowField?: (row: any, column?: any, newValue?: any) => void;
  renderMultiSelectionActions?: ReactElement;
  selectedRowIds?: string[];
  exportConfig?: ExportConfig;
}

export interface CellEditProps {
  mode: 'click' | 'dbclick';
  blurToSave: boolean;
  afterSaveCell: (newData: any) => void;
}

export interface SelectRowProps {
  mode: 'checkbox' | 'radio';
  clickToEdit?: boolean;
  clickToSelect?: boolean;
  hideSelectColumn?: boolean;
  hideSelectAll?: boolean;
  onSelect?: (row: any, isSelect: boolean, rowIndex: number, e: any) => boolean | void;
  onSelectAll?: (isSelect: boolean, rows: any[], e: any) => any[] | void;
}

export interface SortParam {
  dataField: string;
  order: 'asc' | 'desc';
}

export default function DataTable({
  addButton,
  onSecondButtonClick,
  buttonText = 'Agregar',
  buttonIcon = null,
  secondButtonText = '',
  secondButtonIcon = null,
  secondButtonVariant = 'success',
  keyField,
  data: tableData,
  columns,
  defaultSorted = [],
  isDataLoading,
  showSearch = true,
  enablePagination = true,
  pageNumber,
  exportConfig,
  exportFileName = '',
  exportExtraFields = [],
  exportCols,
  customHeaders = [''],
  updateRowField,
  renderMultiSelectionActions,
  selectRow,
  selectedRowIds = [],
  ...otherProps
}: DataTableProps) {
  let data: any[] = [];
  if (tableData && tableData.length > 0) {
    data = tableData;
  }

  const paginationOptions = {
    custom: true,
    totalSize: otherProps.totalSize ? otherProps.totalSize : data.length,
  };

  const curViewURLArr = window.location.href.split('/');
  let curViewName = `${curViewURLArr[curViewURLArr.length - 1]}`;
  if (curViewURLArr.length > 4) {
    curViewName = `${curViewURLArr[curViewURLArr.length - 2]}_${
      curViewURLArr[curViewURLArr.length - 1]
    }`;
  }

  const exportCSV = { fileName: `listado_${curViewName}_${moment().format('YYYYMMDDHHmmss')}.csv` };

  if (!enablePagination) {
    return (
      <ToolkitProvider
        bootstrap4
        keyField={keyField}
        data={data}
        columns={columns}
        exportCSV={exportCSV}
        search>
        {(propsTP) => (
          <CustomBootstrapTable
            addButton={addButton}
            onSecondButtonClick={onSecondButtonClick}
            buttonText={buttonText}
            buttonIcon={buttonIcon}
            secondButtonText={secondButtonText}
            secondButtonIcon={secondButtonIcon}
            secondButtonVariant={secondButtonVariant}
            defaultSorted={defaultSorted}
            isDataLoading={isDataLoading}
            propsTP={propsTP}
            showSearch={showSearch}
            exportConfig={exportConfig}
            updateRowField={updateRowField}
            pageNumber={pageNumber}
            renderMultiSelectionActions={renderMultiSelectionActions}
            selectRow={selectRow}
            exportCols={exportCols}
            {...otherProps}
          />
        )}
      </ToolkitProvider>
    );
  }
  return (
    <ToolkitProvider
      bootstrap4
      keyField={keyField}
      data={data}
      columns={columns}
      exportCSV={Object.keys(exportCSV).length > 0 ? exportCSV : null}
      search>
      {(propsTP) => (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <CustomBootstrapTable
              addButton={addButton}
              buttonText={buttonText}
              buttonIcon={buttonIcon}
              onSecondButtonClick={onSecondButtonClick}
              secondButtonText={secondButtonText}
              secondButtonIcon={secondButtonIcon}
              secondButtonVariant={secondButtonVariant}
              defaultSorted={defaultSorted}
              paginationProps={paginationProps}
              paginationTableProps={paginationTableProps}
              isDataLoading={isDataLoading}
              propsTP={propsTP}
              showSearch={showSearch}
              exportConfig={exportConfig}
              updateRowField={updateRowField}
              pageNumber={pageNumber}
              renderMultiSelectionActions={renderMultiSelectionActions}
              selectRow={selectRow}
              {...otherProps}
            />
          )}
        </PaginationProvider>
      )}
    </ToolkitProvider>
  );
}
