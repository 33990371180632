import React, { ChangeEventHandler } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';

interface FormCheckFieldProps {
  id: string;
  label: string;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: ChangeEventHandler;
}

export default function FormCheckField({
  id,
  defaultChecked,
  disabled,
  onChange,
  ...otherProps
}: FormCheckFieldProps) {
  return (
    <Form.Group controlId={id} className="mt-3">
      <Form.Check
        onChange={onChange}
        type="checkbox"
        name={id}
        defaultChecked={defaultChecked}
        disabled={disabled}
        {...otherProps}
      />
    </Form.Group>
  );
}
