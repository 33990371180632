import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Type } from 'react-bootstrap-table2-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup, ButtonToolbar, Card, Col, Row } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';

import { DataTable, FiltersPanel, FormSelectField } from '../../components';
import FormSelect from '../../components/componentsTs/FormSelect';

import { Marca, Product, ProductCategory1, ProductCategory2, Proveedor } from '../../types/model';
import Utils from '../Utils';
import UIUtils, { QueryParameters } from '../UIUtils';
import APIClient from '../../services/APIClient';
import useDocumentTitle from '../../hooks/useDocumentTitle';

export default function ProductList() {
  useDocumentTitle('Productos');
  const location = useLocation();
  const { addToast } = useToasts();

  const [apiParams, setApiParams] = useState('');
  const [products, setProducts] = useState<Product[]>([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [totalSize, setTotalSize] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    Utils.isPositiveInteger(parseInt(Utils.sanitizeQuery(['page'], location.search).page))
      ? parseInt(Utils.sanitizeQuery(['page'], location.search).page, 10)
      : 1,
  );
  const [marcas, setMarcas] = useState<Marca[]>([]);
  const [proveedores, setProveedores] = useState<Proveedor[]>([]);
  const [productCategories1, setProductCategories1] = useState<ProductCategory1[]>([]);
  const [productCategories2, setProductCategories2] = useState<ProductCategory2[]>([]);
  const [filterStringMarca, setFilterStringMarca] = useState<string | undefined>('');
  const [filterStringProveedor, setFilterStringProveedor] = useState<string | undefined>('');
  const [filterStringProductCategory1, setFilterStringProductCategory1] = useState<
    string | undefined
  >('');
  const [filterStringProductCategory2, setFilterStringProductCategory2] = useState<
    string | undefined
  >('');
  const [selectedMarcaIds, setSelectedMarcaIds] = useState<string[]>([]);
  const [selectedProveedorIds, setSelectedProveedorIds] = useState<string[]>([]);
  const [selectedProductCategory1Codes, setSelectedProductCategory1Codes] = useState<string[]>([]);
  const [selectedProductCategory2Ids, setSelectedProductCategory2Ids] = useState<string[]>([]);
  const [filterActive, setFilterActive] = useState<'active' | 'inactive'>();
  const [isFiltersCollapsed, setIsFiltersCollapsed] = useState(true);

  useEffect(() => {
    getFilterAndEditData();
    loadProducts();
  }, []);

  async function onTableUpdate(queryParameters: QueryParameters) {
    setIsDataLoading(true);
    const { freeText, pagination, sorting } = queryParameters;

    try {
      let { direction, field } = sorting;
      // prepare the URL query for filters
      const filterQuery = [
        filterStringMarca,
        filterStringProductCategory1,
        filterStringProductCategory2,
        filterStringProveedor,
        filterActive === 'active'
          ? 'filter[isEliminado][eq]=false'
          : filterActive === 'inactive'
            ? 'filter[isEliminado][eq]=true'
            : undefined,
      ]
        .filter((filterString) => !!filterString)
        .join('&');

      if (field && field === 'productCategory1') {
        field = field.concat('_code');
      }
      if (field && field === 'productCategory2') {
        field = field.concat('_id');
      }
      if (field && field === 'marca') {
        field = field.concat('Codigo');
      }
      const apiParams = `freeText=${freeText && `%${freeText}%`}&sortField=${
        field || 'id'
      }&sortDir=${direction || 'desc'}&excludeAssocFields=imagenes`;
      const productsRes = await APIClient.get<Product[]>(
        `/products?limit=${pagination.limit}&offset=${pagination.offset}&${apiParams}&${filterQuery}`,
      );
      const products = productsRes.data.data;
      window.history.pushState({ page: pagination.page ?? 1 }, '', `?page=${pagination.page ?? 1}`);
      setApiParams(apiParams);
      setProducts(products);
      setTotalSize(productsRes.data.meta.total);
      setPageNumber(pagination.page ?? 1);
    } catch (error: any) {
      addToast(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      setIsDataLoading(false);
    }
  }

  async function loadProducts() {
    const offset = (pageNumber - 1) * 10;
    try {
      // get paginated list of products sorts by entity id
      const productsRes = await APIClient.get(
        `/products?limit=10&sortField=id&sortDir=desc&offset=${offset}&excludeAssocFields=imagenes`,
      );

      setProducts(productsRes.data.data);
      setIsDataLoading(false);
      setTotalSize(productsRes.data.meta.total);
    } catch (error: any) {
      addToast(`Ocurrió un error: "${error.message}"`, {
        appearance: 'error',
      });
    } finally {
      setIsDataLoading(false);
    }
  }

  //#region Filters  >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  async function getFilterAndEditData() {
    try {
      // get marcas
      const marcasRes = await APIClient.get('/marcas?sortField=descripcion&sortDir=asc');
      // get productCategories1
      const productCategories1Res = await APIClient.get(
        '/product-categories-1?sortField=description&sortDir=asc',
      );
      // get productCategories2
      const productCategories2Res = await APIClient.get(
        '/product-categories-2?sortField=description&sortDir=asc',
      );
      // get proveedores
      const proveedoresRes = await APIClient.get('/proveedores?sortField=nombre&sortDir=asc');

      setMarcas(marcasRes.data.data);
      setProductCategories1(productCategories1Res.data.data);
      setProductCategories2(productCategories2Res.data.data);
      setProveedores(proveedoresRes.data.data);
    } catch (err: any) {
      console.error('Error al obtener la información de los filtros: ', err);
      addToast(`No se pudo obtener la información de los filtros. ${err}`, {
        appearance: 'error',
      });
    }
  }

  function createSelectAllButtons(entityName: string, label?: string) {
    let labelToRender = label;
    if (!labelToRender) {
      labelToRender = `${entityName.substr(0, 1).toUpperCase()}${entityName.substr(1)}`;
    }
    return (
      <p className="m-0">
        {labelToRender} (
        <button
          id={`select-all-${entityName}`}
          type="submit"
          className="link-button text-primary"
          onClick={(e) => handleSelectAll(e, entityName)}>
          Seleccionar todos
        </button>
        )
      </p>
    );
  }

  /**
   * Handle the selection in selects in "multiple" mode, for the filters.
   * @param {object} e - Event object from the select element.
   */
  function handleMultipleFilterChange(e: any) {
    const { id, options } = e.target;

    let fieldName = '';
    let filterQuery: string | undefined;

    switch (id) {
      case 'selectedMarcaIds':
        fieldName = 'marcaCodigo';
        break;

      case 'selectedProductCategory1Codes':
        fieldName = 'category1Code';
        break;

      case 'selectedProductCategory2Ids':
        fieldName = 'category2Id';
        break;

      case 'selectedProveedorIds':
        fieldName = 'proveedorId';
        break;

      default:
        break;
    }

    let whereOp = [...options].filter((opt) => opt.selected).length > 1 ? 'or' : 'eq';
    let values = [...options].filter((opt) => opt.selected).map((opt) => opt.value);

    // if all options are selected clears the filter
    if (values.length == options.length) {
      filterQuery = Utils.prepareQueryToFilter(fieldName, '', []);
    } else {
      filterQuery = Utils.prepareQueryToFilter(fieldName, whereOp, values);
    }

    switch (id) {
      case 'selectedMarcaIds':
        setSelectedMarcaIds(values);
        setFilterStringMarca(filterQuery);
        break;

      case 'selectedProductCategory1Codes':
        setSelectedProductCategory1Codes(values);
        setFilterStringProductCategory1(filterQuery);
        break;

      case 'selectedProductCategory2Ids':
        setSelectedProductCategory2Ids(values);
        setFilterStringProductCategory2(filterQuery);
        break;

      case 'selectedProveedorIds':
        setSelectedProveedorIds(values);
        setFilterStringProveedor(filterQuery);
        break;

      default:
        break;
    }
  }

  function handleFilterChange(e: any) {
    const { id, value } = e.target;

    switch (id) {
      case 'selectedFilterActive':
        setFilterActive(value == '' ? undefined : value);
        break;
      default:
        console.debug(`Unexpected filter field ID: "${id}"`);
        break;
    }
  }

  function handleSelectAll(e: any, entityName: any) {
    let valueIds: string[] = [];
    let selectedName = '';
    let filterName = '';
    let fieldName = '';

    // map ids from entities into "selected" variables
    switch (entityName) {
      case 'marcas':
        valueIds = marcas.map((brand: Marca) => brand.codigo);
        setSelectedMarcaIds(valueIds);
        setFilterStringMarca(Utils.prepareQueryToFilter('marcaCodigo', '', []));
        break;

      case 'productCategories1':
        valueIds = productCategories1.map((category: ProductCategory1) => category.code);
        setSelectedProductCategory1Codes(valueIds);
        setFilterStringProductCategory1(Utils.prepareQueryToFilter('producId', '', []));
        break;

      case 'productCategories2':
        valueIds = productCategories2.map((category: ProductCategory2) => String(category.id));
        setSelectedProductCategory2Ids(valueIds);
        setFilterStringProductCategory2(Utils.prepareQueryToFilter('producId', '', []));
        break;

      case 'proveedores':
        valueIds = proveedores.map((supplier: Proveedor) => String(supplier.id));
        setSelectedProveedorIds(valueIds);
        setFilterStringProveedor(Utils.prepareQueryToFilter('proveedorId', '', []));
        break;

      default:
        return;
    }
  }

  function handleFiltersCollapseClick(e: any) {
    e.preventDefault();
    setIsFiltersCollapsed((prevState) => !prevState);
  }

  function handleFiltersCleanClick() {
    setSelectedMarcaIds([]);
    setSelectedProductCategory1Codes([]);
    setSelectedProductCategory2Ids([]);
    setSelectedProveedorIds([]);
    setFilterStringMarca('');
    setFilterStringProductCategory1('');
    setFilterStringProductCategory2('');
    setFilterStringProveedor('');
    setFilterActive(undefined);
  }

  //#endregion Filters  <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  //#region Edit Cells >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  async function updateRowField(row: any, column: any) {
    try {
      //call to api UPDATE
      await APIClient.patch(`/products/${row.id}`, row);
      addToast(`Cambio guardado`, {
        appearance: 'success',
      });
      // Update the component's state with the changes
      const productToUpdateState = await APIClient.get(`/products/${row.id}`);
      const arrayToUpdateState = products.map((art) => {
        if (art.id === row.id) {
          return productToUpdateState.data.data;
        }
        return art;
      });
      setProducts(arrayToUpdateState);
    } catch (err: any) {
      console.error('Error al actualizar el campo: ', err);
      addToast(`No se pudo guardar la información. Inténtelo nuevamente. ${err}`, {
        appearance: 'error',
      });
    }
  }

  //#endregion Edit Cells <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  const events = {
    onMouseEnter: (e: any) => {
      e.target.style.cursor = `pointer`;
    },
  };

  const columns = [
    {
      dataField: 'erpCodigo',
      text: 'Código ERP',
      sort: true,
      events: events,
    },
    {
      dataField: 'codigoEan13',
      text: 'EAN',
      sort: true,
      events: events,
    },
    {
      dataField: 'descripcion',
      text: 'Descripción',
      sort: true,
      events: events,
    },
    {
      dataField: 'marcaCodigo',
      text: 'Marca',
      sort: true,
      events: events,
      formatter: (cellContent: any, row: any) => {
        if (cellContent === null) {
          return '';
        }
        const marcaFound = marcas.find((marca) => cellContent === marca.codigo);
        const marca = marcaFound ? marcaFound.descripcion : row.marca.descripcion;
        return marca;
      },
      editor: {
        type: Type.SELECT,
        getOptions: (row: any, column: any) => {
          return marcas.map((marca) => {
            return { value: marca.codigo, label: marca.descripcion };
          });
        },
      },
    },
    {
      dataField: 'category1Code',
      text: 'Categoría 1',
      sort: true,
      events: events,
      formatter: (cellContent: any, row: any) => {
        if (cellContent === null) {
          return '';
        }
        const productCategory1Found = productCategories1.find((lin) => cellContent === lin.code);
        const productCategory1 = productCategory1Found
          ? productCategory1Found.description
          : row.productCategory1.descripcion;
        return productCategory1;
      },
      editor: {
        type: Type.SELECT,
        getOptions: (row: any, column: any) => {
          return productCategories1.map((lin) => {
            return { value: lin.code, label: lin.description };
          });
        },
      },
    },
    {
      dataField: 'proveedorId',
      text: 'Proveedor',
      csvExport: false,
      events: events,
      formatter: (cellContent: any, row: any) => {
        if (cellContent === null) {
          return '';
        }
        cellContent = typeof cellContent === 'string' ? parseInt(cellContent, 10) : cellContent;
        const proveedorFound = proveedores.find((proveedor) => cellContent === proveedor.id);
        const proveedor = proveedorFound ? proveedorFound.nombre : row.proveedor.nombre;
        return proveedor;
      },
      editor: {
        type: Type.SELECT,
        getOptions: (row: any, column: any) => {
          return proveedores.map((proveedor) => {
            return { value: proveedor.id, label: proveedor.nombre };
          });
        },
      },
    },
    {
      dataField: 'isEliminado',
      text: 'Estado',
      //si el flag es false el producto está activo
      csvExport: false,
      events: UIUtils.bgBlueOnMouseEnter,
      formatter: (cellContent: any, row: any) => {
        if (cellContent === null) {
          return '';
        }
        cellContent =
          typeof cellContent !== 'boolean' ? Utils.stringToBoolean(cellContent) : cellContent;
        const UISettings = {
          text: { true: 'Inactivo', false: 'Activo' },
          color: { true: 'danger', false: 'success' },
        };
        return UIUtils.getStatusBadge(cellContent, UISettings);
      },
      editor: {
        type: Type.SELECT,
        getOptions: (row: any, column: any) => {
          const arrayForOptionsEstado = [
            { value: false, label: 'Activo' },
            { value: true, label: 'Inactivo' },
          ];
          return arrayForOptionsEstado.map((estado) => {
            return { value: estado.value, label: estado.label };
          });
        },
      },
    },
    {
      dataField: 'isAgotado',
      text: 'Stock',
      //si el flag es false el producto está en stock
      csvExport: false,
      events: UIUtils.bgBlueOnMouseEnter,
      formatter: (cellContent: any, row: any) => {
        if (cellContent === null) {
          return '';
        }
        cellContent = typeof cellContent !== 'number' ? parseInt(cellContent, 10) : cellContent;
        const UISettings = {
          text: { true: 'Agotado', false: 'En stock' },
          color: { true: 'danger', false: 'success' },
        };
        return UIUtils.getStatusBadge(cellContent, UISettings);
      },
      editor: {
        type: Type.SELECT,
        getOptions: (row: any, column: any) => {
          const arrayForOptionsEstado = [
            { value: 0, label: 'En stock' },
            { value: 1, label: 'Agotado' },
          ];
          return arrayForOptionsEstado.map((estado) => {
            return { value: estado.value, label: estado.label };
          });
        },
      },
    },
    {
      dataField: 'isPromo',
      text: 'Promoción',
      //si el flag es false el producto está en NO está en promoción
      csvExport: false,
      events: UIUtils.bgBlueOnMouseEnter,
      formatter: (cellContent: any, row: any) => {
        if (cellContent === null) {
          return '';
        }
        cellContent = typeof cellContent !== 'number' ? parseInt(cellContent, 10) : cellContent;
        const UISettings = {
          text: { true: 'Sí', false: 'No' },
          color: { true: 'success', false: 'danger' },
        };
        return UIUtils.getStatusBadge(cellContent, UISettings);
      },
      editor: {
        type: Type.SELECT,
        getOptions: (row: any, column: any) => {
          const arrayForOptionsEstado = [
            { value: 0, label: 'No' },
            { value: 1, label: 'Sí' },
          ];
          return arrayForOptionsEstado.map((estado) => {
            return { value: estado.value, label: estado.label };
          });
        },
      },
    },
    {
      dataField: 'actions',
      isDummyField: true,
      text: '',
      csvExport: false,
      formatter: (cellContent: any, row: any) => (
        <ButtonToolbar>
          <ButtonGroup>
            <Link to={{ pathname: `/products/${row.id}`, search: `?page=${pageNumber}` }}>
              <Button size="sm" variant="outline-primary" title="Editar">
                <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
              </Button>
            </Link>
          </ButtonGroup>
        </ButtonToolbar>
      ),
    },
  ];

  return (
    <div>
      <h1 className="page-title">Productos</h1>

      <FiltersPanel
        isDataLoading={isDataLoading}
        onFilterClick={() =>
          onTableUpdate({
            freeText: '',
            pagination: { limit: 10, offset: 0 },
            sorting: {},
          })
        }
        onClearClick={handleFiltersCleanClick}>
        <Row>
          <Col md={6}>
            <FormSelect
              id="selectedMarcaIds"
              label={createSelectAllButtons('marcas')}
              value={selectedMarcaIds}
              onChange={handleMultipleFilterChange}
              choices={marcas}
              choiceIdField="codigo"
              choiceLabelField="descripcion"
              multiple
            />
          </Col>
          <Col md={6}>
            <FormSelect
              id="selectedProductCategory1Codes"
              label={createSelectAllButtons('productCategories1', 'Categorías 1')}
              value={selectedProductCategory1Codes}
              onChange={handleMultipleFilterChange}
              choices={productCategories1}
              choiceIdField="code"
              choiceLabelField="description"
              multiple
            />
          </Col>
          <Col md={6}>
            <FormSelect
              id="selectedProductCategory2Ids"
              label={createSelectAllButtons('productCategories2', 'Categorías 2')}
              value={selectedProductCategory2Ids}
              onChange={handleMultipleFilterChange}
              choices={productCategories2}
              choiceIdField="id"
              choiceLabelField="description"
              multiple
            />
          </Col>
          <Col md={6} className="my-3">
            <FormSelect
              id="selectedProveedorIds"
              label={createSelectAllButtons('proveedores')}
              value={selectedProveedorIds}
              onChange={handleMultipleFilterChange}
              choices={proveedores}
              choiceIdField="id"
              choiceLabelField="nombre"
              multiple
            />
          </Col>
          <Col md={6}>
            <FormSelectField
              id="selectedFilterActive"
              label="Activo"
              value={filterActive}
              onChange={handleFilterChange}
              placeholder="(Todos)"
              choices={[
                { code: 'active', description: 'Activos' },
                { code: 'inactive', description: 'Inactivos' },
              ]}
              choiceIdField="code"
              choiceLabelField="description"
            />
          </Col>
        </Row>
      </FiltersPanel>

      <DataTable
        remote={{
          filter: true,
          pagination: true,
          sort: true,
        }}
        totalSize={totalSize}
        columns={columns}
        data={products}
        onTableUpdate={onTableUpdate}
        isDataLoading={isDataLoading}
        keyField="id"
        exportConfig={{
          exportURL: `/products/export.xlsx?${apiParams}`,
        }}
        updateRowField={updateRowField}
        pageNumber={pageNumber}
      />
    </div>
  );
}
