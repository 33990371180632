import React from 'react';
import PropTypes from 'prop-types';
import { withToastManager } from 'react-toast-notifications';
import { DataTable } from '../../components';
import APIClient from '../../services/APIClient';
import { ProductCategory2ListState } from './productCategory1Interface';
import { Props } from '../Interfaces/interfaces';
import { withDocumentTitle } from '../withDocumentTitle';

class ProductCategory2List extends React.Component<Props, ProductCategory2ListState> {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isDataLoading: true,
      productCategories2: [],
    };

    this.loadProductCategories2 = this.loadProductCategories2.bind(this);
  }

  componentDidMount() {
    this.loadProductCategories2();
  }

  loadProductCategories2() {
    const { toastManager } = this.props;

    APIClient.get('/product-categories-2')
      .then((res) => {
        this.setState({
          isDataLoading: false,
          productCategories2: res.data.data,
        });
      })
      .catch((err) => {
        console.error(err);
        toastManager.add(`Ocurrió un error: "${err.message}"`, {
          appearance: 'error',
        });
        this.setState({
          isDataLoading: false,
        });
      });
  }

  render() {
    const { isDataLoading, productCategories2 } = this.state;

    const columns = [
      {
        dataField: 'id',
        text: 'ID',
        sort: true,
      },
      {
        dataField: 'description',
        text: 'Descripción',
        sort: true,
      },
      // {
      //   dataField: 'actions',
      //   isDummyField: true,
      //   text: '',
      //   formatter: (cellContent, row) => (
      //     <ButtonToolbar>
      //       <ButtonGroup>
      //         <LinkContainer to={`/product-categories-2/${row.id}`}>
      //           <Button size="sm" variant="outline-primary" title="Editar">
      //             <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
      //           </Button>
      //         </LinkContainer>
      //       </ButtonGroup>
      //     </ButtonToolbar>
      //   ),
      // },
    ];

    const exportColumns = [
      {
        fieldName: 'id',
        title: 'ID',
      },
      {
        fieldName: 'description',
        title: 'Descripción',
      },
    ];

    return (
      <div>
        <h1 className="page-title">Categoría 2</h1>

        <DataTable
          columns={columns}
          data={productCategories2}
          isDataLoading={isDataLoading}
          keyField="id"
          exportConfig={{
            exportURL: '/product-categories-2/export.xlsx',
            columns: exportColumns,
          }}
        />
      </div>
    );
  }
}

export default withToastManager(withDocumentTitle(ProductCategory2List, 'Categoría 2'));
