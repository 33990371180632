import React from 'react';
import PropTypes from 'prop-types';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagic } from '@fortawesome/free-solid-svg-icons';

export type SelectFieldChangeEvent = React.ChangeEvent<HTMLSelectElement> & {
  /** Only set when multiple is true */
  selectedValues?: string[];
};

export interface FormSelectFieldProps {
  id: string;
  label: string | React.ReactElement;
  choices: Array<{ [key: string]: any }>;
  value?: string | Array<string>;
  defaultValue?: string | number | Array<string> | undefined;
  required?: boolean;
  placeholder?: string;
  multiple?: boolean;
  undefinedOnAllSelected?: boolean;
  choiceIdField: string;
  choiceLabelField: string | ((choice: { [key: string]: any }) => string);
  arrayvalidateindex?: Array<string>;
  disabled?: boolean;
  onChange?: (e: SelectFieldChangeEvent) => void;
  aiStatusIconColor?: string;
  aiInterpretDescription?: string;
}

export default function FormSelectField({
  id,
  label,
  choices,
  value,
  defaultValue = undefined,
  required = false,
  placeholder = '(Seleccione)',
  choiceIdField = 'id',
  choiceLabelField = 'label',
  arrayvalidateindex = [],
  disabled = false,
  onChange = () => {},
  aiStatusIconColor = undefined,
  aiInterpretDescription = undefined,
  multiple,
  undefinedOnAllSelected = true,
  ...otherProps
}: FormSelectFieldProps) {
  const aiTooltip = <Tooltip>{aiInterpretDescription}</Tooltip>;

  function handleOnChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const { options } = e.target;
    const newEventArg: SelectFieldChangeEvent = e;

    if (multiple) {
      const selectedValues = [...options].filter((opt) => opt.selected).map((opt) => opt.value);
      if (selectedValues) {
        if (undefinedOnAllSelected && selectedValues.length === options.length) {
          // if all options are selected, then selectedValues will be undefined
          newEventArg.selectedValues = undefined;
        } else {
          newEventArg.selectedValues = selectedValues;
        }
      }
    }
    onChange?.(newEventArg);
  }

  return (
    <Form.Group className="mt-3" controlId={id}>
      <Form.Label>{label}</Form.Label>
      <div className="d-flex align-items-center justify-content-between">
        <Form.Select
          name={id}
          multiple={multiple}
          as="select"
          value={value}
          className={
            arrayvalidateindex && arrayvalidateindex.indexOf(id) >= 0 ? 'border border-danger' : ''
          }
          defaultValue={defaultValue}
          onChange={handleOnChange}
          {...otherProps}>
          {!required && !multiple && <option value="">{placeholder}</option>}
          {choices.map((choice) => (
            <option value={choice[choiceIdField]} key={choice[choiceIdField]}>
              {typeof choiceLabelField === 'string'
                ? choice[choiceLabelField]
                : choiceLabelField(choice)}
            </option>
          ))}
        </Form.Select>
        {aiInterpretDescription && (
          <OverlayTrigger overlay={aiTooltip}>
            <div className="ms-2">
              <FontAwesomeIcon icon={faMagic} color={aiStatusIconColor} fixedWidth />
            </div>
          </OverlayTrigger>
        )}
      </div>
      <Form.Control.Feedback type="invalid">Please provide a valid zip.</Form.Control.Feedback>
    </Form.Group>
  );
}
