import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import APIClient from '../services/APIClient';
import { AppDispatchActionTypes, useAppContext } from '../context/AppContext';
import { Connector } from '../types/model';

export default function ProtectedRoutes() {
  const { appDispatch } = useAppContext();

  const [isLoading, setLoading] = useState<boolean>(true);
  const [isRedirect, setRedirect] = useState<boolean>(false);

  let loadConnectorsInterval: NodeJS.Timeout;

  // this will be called on each render
  useEffect(() => {
    console.debug('Checking token...');

    APIClient.get('/check-token')
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
        } else {
          const error = new Error(res.statusText);
          throw error;
        }
      })
      .catch((err) => {
        console.warn('Not logged in', err);
        setLoading(false);
        setRedirect(true);
      });
  });

  useEffect(() => {
    // only get connectors if authenticated
    loadConnectors();
    loadConnectorsInterval = setInterval(
      () => {
        loadConnectors();
      },
      // 30 minutes
      30 * 60 * 1000,
    );

    return () => {
      clearInterval(loadConnectorsInterval);
    };
  }, []);

  /**
   * Load the connectors to get a global status
   */
  async function loadConnectors() {
    try {
      const connectorsResponse = await APIClient.get<Connector[]>('/connectors');
      const connectors = connectorsResponse.data.data;

      appDispatch({
        type: AppDispatchActionTypes.SET_CONNECTORS,
        payload: connectors,
      });
    } catch (error) {
      console.error('Error in loading Connectors', error);
    }
  }

  if (isLoading) {
    return null;
  }
  if (isRedirect) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
}
