import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Badge } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight, faCircle, faPlug } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faCirclePlay, faCirclePause } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Connector, ConnectorSummary } from '../../types/model';
import apiClient from '../../services/APIClient';
import { Loading } from '../../components';
import { AppDispatchActionTypes, useAppContext } from '../../context/AppContext';

export default function ConnectorDashboard() {
  const { addToast } = useToasts();
  const { appState, appDispatch } = useAppContext();

  const [isDataLoading, setIsDataLoading] = useState(true);
  const [whatsAppConnectors, setWhatsAppConnectors] = useState<ConnectorSummary[]>();
  const [emailConnectors, setEmailConnectors] = useState<ConnectorSummary[]>();
  const [otherConnectors, setOtherConnectors] = useState<ConnectorSummary[]>([]);

  useEffect(() => {
    setIsDataLoading(true);
    apiClient
      .get<Connector[]>('/connectors')
      .then((response) => {
        const connectors = response.data.data;

        appDispatch({
          type: AppDispatchActionTypes.SET_CONNECTORS,
          payload: connectors,
        });
      })
      .catch((error) => {
        console.error('Error in loading Connectors', error);
        addToast(`Ocurrió un error cargando las integraciones. ${error.message}`, {
          appearance: 'error',
        });
      });
  }, []);

  useEffect(() => {
    if (appState.connectors.length > 0) {
      const whatsappConnectors = appState.connectors.filter((c) => c.type === 'whatsapp');
      const emailConnectors = appState.connectors.filter((c) => c.type === 'email');
      const restConnectors = appState.connectors.filter(
        (c) => c.type !== 'whatsapp' && c.type !== 'email',
      );
      setWhatsAppConnectors(whatsappConnectors);
      setEmailConnectors(emailConnectors);
      setOtherConnectors(restConnectors);
    } else {
      // empty the state
      setWhatsAppConnectors([]);
      setEmailConnectors([]);
      setOtherConnectors([]);
    }
    // connector local arrays set, finished loading
    setIsDataLoading(false);
  }, [appState.connectors]);

  function ConnectorItem({
    connector,
    icon = faPlug,
    name,
  }: {
    name?: string;
    connector?: ConnectorSummary;
    icon?: IconProp;
  }) {
    return (
      <Card className={`connector-card mb-3 ${connector ? '' : 'text-body-tertiary'}`}>
        <Card.Body className="position-relative">
          {/* status icon */}
          {connector && (
            <div className="connector-status position-absolute">
              {connector.isEnabled ? (
                <FontAwesomeIcon
                  icon={faCircle}
                  fixedWidth
                  title="Activo"
                  className="text-success"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCircle}
                  fixedWidth
                  title="Inactivo"
                  className="text-dark-emphasis"
                />
              )}
            </div>
          )}

          <Row>
            <Col className="col-auto">
              <FontAwesomeIcon icon={icon} fixedWidth size="4x" />
            </Col>
            <Col className="text-left">
              <h3>
                {connector?.name ?? name} {!connector && <small>(desactivado)</small>}
              </h3>
              {!connector ? <p>(No existen cuentas configuradas)</p> : null}
              {connector?.connectorLinks?.length &&
                connector?.connectorLinks.map((link, index) => (
                  <div key={link.id}>
                    <h4 className="fs-5">
                      {link.isEnabled ? (
                        <FontAwesomeIcon
                          icon={faCirclePlay}
                          fixedWidth
                          title="Corriendo"
                          className="text-success me-1"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCirclePause}
                          fixedWidth
                          title="En pausa"
                          className="text-warning me-1"
                        />
                      )}
                      {link.name ?? `Cuenta #${index + 1}`}
                    </h4>
                    {/* Only display sync info if there's a cron setup, otherwise it doesn't make sense */}
                    {link.cron && (
                      <>
                        <div>
                          <label className="me-2">Última sync:</label>
                          {link.lastExecution ? (
                            <span title={moment(link.lastExecution?.createdAt).toString()}>
                              {moment(link.lastExecution?.createdAt).fromNow(true)}
                            </span>
                          ) : (
                            <span>(sin sincronizaciones)</span>
                          )}
                        </div>
                        <div>
                          <label className="me-2">Próxima sync:</label>

                          {link.nextExecutionAt ? (
                            <span title={moment(link.nextExecutionAt).toString()}>
                              {moment(link.nextExecutionAt).toNow(true)}
                            </span>
                          ) : (
                            <span>-</span>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                ))}
            </Col>
            <Col className="col-auto align-self-center position-relative">
              {connector ? (
                <Link to={`/connectors/${connector?.code}`} className="stretched-link">
                  <FontAwesomeIcon icon={faChevronRight} fixedWidth size="4x" />
                </Link>
              ) : null}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  if (isDataLoading) {
    return <Loading />;
  }

  return (
    <div>
      <h1 className="page-title">Integraciones</h1>

      {/* Standard connectors */}
      <Row>
        {whatsAppConnectors && whatsAppConnectors.length > 0 ? (
          whatsAppConnectors.map((connector) => (
            <Col lg={6}>
              <ConnectorItem
                key={connector.code}
                connector={connector}
                icon={faWhatsapp}
                name="WhatsApp"
              />
            </Col>
          ))
        ) : (
          <Col lg={6}>
            <ConnectorItem icon={faWhatsapp} name="WhatsApp" />
          </Col>
        )}
        {emailConnectors && emailConnectors.length > 0 ? (
          emailConnectors.map((connector) => (
            <Col lg={6}>
              <ConnectorItem
                key={connector.code}
                connector={connector}
                icon={faEnvelope}
                name="E-mail"
              />
            </Col>
          ))
        ) : (
          <Col lg={6}>
            <ConnectorItem icon={faEnvelope} name="E-mail" />
          </Col>
        )}
      </Row>

      <Row>
        {otherConnectors.map((connector) => (
          <Col lg={6}>
            <ConnectorItem key={connector.code} connector={connector} />
          </Col>
        ))}
      </Row>
    </div>
  );
}
