import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Conversation, ConversationMessage } from '../types/model';
import {
  SenderType,
  DownloadFileType,
  ConversationMessageStatus,
  SourceChannel,
} from '../constants';
import { getTypeFromMimeType } from './utils';
import APIClient from '../services/APIClient';
import config from '../config';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
  faCheck,
  faCheckDouble,
  faClock,
  faFile,
  faFileCsv,
  faFileExcel,
  faFilePdf,
  faInfoCircle,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ConversationMessageBubbleProps {
  message: ConversationMessage;
  conversation: Conversation;
}

interface FileContainer {
  index: number;
  name: string;
  mimeType: string;
  status: string;
  file: Blob | null;
  ref: React.RefObject<HTMLAudioElement> | null;
}

export default function ConversationMessageBubble({
  message,
  conversation,
}: ConversationMessageBubbleProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const [filesContainer, setFilesContainer] = useState<FileContainer[]>([]);
  const [content, setContent] = useState<string | null>(null);

  useEffect(() => {
    Promise.allSettled(
      message.files.map((file) =>
        APIClient.getFile(`${config.api.url}/files/${file.id}`, { responseType: 'arraybuffer' }),
      ),
    )
      .then((responses) => {
        const localFilesContainer = responses.map((response, index: number) =>
          createFileContainer(response, index),
        );
        localFilesContainer.sort((a, b) => a.mimeType.localeCompare(b.mimeType));
        setFilesContainer(localFilesContainer);
      })
      .catch((error) => {
        console.error('Error descargando archivos adjuntos.', error);
        setFilesContainer([
          {
            index: 0,
            name: '',
            mimeType: '',
            status: 'rejected',
            file: null,
            ref: null,
          },
        ]);
      })
      .finally(() => {
        setLoading(false);
      });
    processMarkdown(message.content ?? '');

    return () => {
      setFilesContainer([]);
      setContent(null);
      setLoading(true);
    };
  }, []);

  useEffect(() => {
    filesContainer.forEach((file) => {
      if (file.ref && file.ref.current) {
        file.ref.current.load();
      }
    });
  }, [loading]);

  function createFileContainer(response: PromiseSettledResult<any>, index: number): FileContainer {
    const audioRef = React.createRef<HTMLAudioElement>();

    return {
      index,
      name: message.files[index].name,
      mimeType: message.files[index].mimeType.split(';')[0],
      status: response.status,
      file:
        response.status === 'fulfilled'
          ? new Blob([response.value.data], { type: message.files[index].mimeType.split(';')[0] })
          : null,
      ref: audioRef,
    };
  }

  function processMarkdown(markdown: string) {
    // TODO: sanitize message content
    let localContent = markdown.replace(/(?<![{[?}\]])\*\*(?!\s)(.+?)\*\*/g, '<strong>$1</strong>');
    localContent = localContent.replace(/(?<![{[?}\]])__(?!\s)(.+?)__/g, '<em>$1</em>');
    localContent = localContent.replace(/(?<![{[?}\]])~~(?!\s)(.+?)~~/g, '<s>$1</s>');
    setContent(localContent);
  }

  function getIconFromDownloadFileType(downloadFileType: DownloadFileType) {
    switch (downloadFileType) {
      case DownloadFileType.pdf:
        return <FontAwesomeIcon icon={faFilePdf} fixedWidth />;
      case DownloadFileType.csv:
        return <FontAwesomeIcon icon={faFileCsv} fixedWidth />;
      case DownloadFileType.spreadsheet:
        return <FontAwesomeIcon icon={faFileExcel} fixedWidth />;
      default:
        return <FontAwesomeIcon icon={faFile} fixedWidth />;
    }
  }

  function handleSaveButtonClick(e: any, fileContainer: FileContainer) {
    e.preventDefault();
    saveAs(
      fileContainer.file,
      `adjunto_.${moment(new Date()).format('DD-MM-YYYY_HH:mm')}.${fileContainer.name
        .split('.')
        .at(-1)}`,
    );
  }

  function renderReadStatusIcons() {
    switch (message.status) {
      case ConversationMessageStatus.pending:
        return <FontAwesomeIcon icon={faClock} fixedWidth className="ms-1" title="Pendiente" />;
      case ConversationMessageStatus.sent:
      case ConversationMessageStatus.received:
        return (
          <FontAwesomeIcon icon={faCheck} fixedWidth className="ms-1" title="Enviado / Entregado" />
        );
      case ConversationMessageStatus.read:
        return <FontAwesomeIcon icon={faCheckDouble} fixedWidth className="ms-1" title="Leído" />;
      case ConversationMessageStatus.error:
        return <FontAwesomeIcon icon={faXmark} fixedWidth className="ms-1" title="Error" />;
      default:
        return <FontAwesomeIcon icon={faClock} fixedWidth className="ms-1" title="(desconocido)" />;
    }
  }

  function renderMessageTimestampAndStatus() {
    const timeFormat = conversation.sourceChannel == SourceChannel.email ? 'LLLL' : 'HH:mm';

    return (
      <div
        className={`info ${
          message.senderType === SenderType.user ? 'user-message' : 'otb-message'
        }`}>
        <span className="time-status">
          {moment(message.timestamp).format(timeFormat)}
          {message.senderType != SenderType.user && renderReadStatusIcons()}
        </span>
        {message.status == ConversationMessageStatus.pending && (
          <span className="d-block">
            <FontAwesomeIcon icon={faInfoCircle} fixedWidth className="me-1" />
            El mensaje aún no se ha enviado. Esto puede deberse a que aún no se ejecutó el proceso
            de envío o a que este contacto posee conversaciones abiertas. Los mensajes se enviarán
            una vez que las conversaciones hayan finalizado.
          </span>
        )}
        {message.status == ConversationMessageStatus.error && (
          <span className="d-block text-danger">
            <FontAwesomeIcon icon={faInfoCircle} fixedWidth className="me-1" />
            Ha ocurrido un error al enviar el mensaje.
          </span>
        )}
      </div>
    );
  }

  return (
    <div
      className={`message-bubble ${conversation.sourceChannel} ${
        message.senderType === SenderType.user ? 'user-message' : 'otb-message'
      } ${
        // italic and grayed if pending or error
        [ConversationMessageStatus.pending, ConversationMessageStatus.error].includes(
          message.status,
        )
          ? 'fst-italic text-body-secondary'
          : ''
      }`}>
      {conversation.sourceChannel == SourceChannel.email && renderMessageTimestampAndStatus()}
      <span
        className={`${conversation.sourceChannel == SourceChannel.email ? 'font-monospace' : ''}`}
        style={{ whiteSpace: 'break-spaces' }}
        dangerouslySetInnerHTML={{
          __html: content !== null && !loading ? content : 'Cargando...',
        }}></span>
      <div className="file-container">
        {filesContainer.length > 0 &&
          filesContainer.map((localFileContainer, index: number) => {
            if (localFileContainer.status !== 'fulfilled') {
              return (
                <span>{`Error cargando recurso ${localFileContainer.name.split('.').at(-1)}`}</span>
              );
            }
            switch (getTypeFromMimeType(localFileContainer.mimeType)) {
              case DownloadFileType.audio:
                return (
                  localFileContainer.file && (
                    <div>
                      <audio
                        key={`audio-${index}-${localFileContainer.name}`}
                        className="audio order-audio"
                        ref={localFileContainer.ref}
                        controls={true}>
                        <source
                          src={URL.createObjectURL(localFileContainer.file)}
                          type={localFileContainer.mimeType}
                        />
                      </audio>
                      <button
                        style={{ display: 'none' }}
                        type="button"
                        onClick={(event) =>
                          localFileContainer.ref && localFileContainer.ref.current?.play()
                        }>
                        PLAY
                      </button>
                    </div>
                  )
                );

              case DownloadFileType.image:
                return (
                  <div key={index} className="image order-image">
                    <img
                      className="source-order-img"
                      alt="imagen de pedido"
                      src={URL.createObjectURL(localFileContainer.file!)}
                    />
                    <button
                      type="button"
                      onClick={(event) => handleSaveButtonClick(event, localFileContainer)}>
                      Descargar
                    </button>
                  </div>
                );

              default:
                return (
                  <div key={index} className="d-flex align-items-center">
                    {getIconFromDownloadFileType(getTypeFromMimeType(localFileContainer.mimeType))}
                    <button
                      key={index}
                      type="button"
                      className="file order-application"
                      onClick={(event) => handleSaveButtonClick(event, localFileContainer)}>
                      {`Descargar ${localFileContainer.name.split('.').at(-1)} adjunto.`}
                    </button>
                  </div>
                );
            }
          })}
      </div>
      {conversation.sourceChannel !== SourceChannel.email && renderMessageTimestampAndStatus()}
    </div>
  );
}
