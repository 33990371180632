import React from 'react';
import { Route } from 'react-router-dom';
import VendedorList from './VendedorList';
import VendedorEdit from './VendedorEdit';

const routes = () =>
  [
    // <Route path="/agents/nuevo" exact element={<VendedorEdit />} key="/agents/nuevo" />,
    <Route path="/agents/:id" element={<VendedorEdit />} key="/agents/:id" />,
    <Route path="/agents" element={<VendedorList />} key="/agents" />,
  ].map((route) => route);

export default routes;
