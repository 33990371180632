import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import Moment from 'react-moment';

import Login from './views/Security/Login';
import ProtectedRoutes from './views/ProtectedRoutes';
import Remito from './views/Remito';
import { AppContextProvider } from './context/AppContext';

import './App.scss';
import Layout from './views/Layout';

// Moment defaults
Moment.globalFormat = 'YYYY-MM-DD HH:mm';

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedRoutes />}>
        <Route path="/remitos/:id" element={<Remito />} />
        <Route path="/*" element={<Layout />} />
      </Route>
    </Routes>
  );
}

/**
 * AppWrapper component that sets up the application with essential providers.
 * It includes:
 * - BrowserRouter: To enable routing within the application.
 * - ToastProvider: For displaying toast notifications with a 6-second auto-dismiss timeout.
 * - AppContextProvider: To provide application-wide context state and dispatch.
 *
 * @returns {ReactElement} The configured application component.
 */
export default function AppWrapper() {
  return (
    <BrowserRouter>
      <ToastProvider autoDismissTimeout="6000" placement="bottom-right">
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </ToastProvider>
    </BrowserRouter>
  );
}
