import { faKey, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Container, Dropdown, DropdownButton, Image, Nav, Navbar, Row } from 'react-bootstrap';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Security from '../services/Security';

import NotFound from './NotFound';
import PasswordChange from './PasswordChange';
import SideMenu from './SideMenu';

// routes
import productRoutes from './Product/ProductRoutes';
import clienteRoutes from './Cliente/clienteRoutes';
import conversationRoutes from './Conversation/conversationRoutes';
import clienteTipoRoutes from './ClienteTipo/clienteTipoRoutes';
import productCategory1Routes from './ProductCategory1/productCategory1Routes';
import productCategory2Routes from './ProductCategory2/productCategory2Routes';
import productCategory3Routes from './ProductCategory3/productCategory3Routes';
import listaPrecioRoutes from './ListaPrecio/ListaPrecioRoutes';
import marcaRoutes from './Marca/marcaRoutes';
import orderRoutes from './Order/orderRoutes';
import usuarioRoutes from './Usuario/usuarioRoutes';
import vendedorRoutes from './Vendedor/VendedorRoutes';
import zonaRoutes from './Zona/ZonaRoutes';
import puntoEntregaRoutes from './PuntoEntrega/PuntoEntregaRoutes';
import connectorRoutes from './Connector/connectorRoutes';
import unitRoutes from './Unit/unitRoutes';
import companyRoutes from './Company/CompanyRoutes';
import useTheme from '../hooks/useTheme';

export default function Layout() {
  const theme = useTheme();
  const [session, setSession] = useState<any>();

  const navigate = useNavigate();

  useEffect(() => {
    setSession(Security.getSession());
  }, []);

  function changePasswordRedirect(event) {
    event.preventDefault(event);
    navigate('/cambiar-password');
  }

  function logout(event) {
    event.preventDefault(event);

    Security.clearSession();
    navigate('/login');
  }

  // prepare styles
  const navBarStyle: any = {};
  if (theme.colors?.navBar) {
    navBarStyle.backgroundColor = `${theme.colors.navBar}`;
  }

  return (
    <>
      <Navbar
        bg={theme.colors?.navBar ? '' : 'dark'}
        fixed="top"
        variant="dark"
        className="flex-md-nowrap p-0 shadow"
        style={navBarStyle}>
        <Navbar.Brand href="/" className="col-sm-3 col-md-2 me-0">
          <Container className="ms-0 px-4 py-0">
            <Image className="header-logo mw-100" src={theme.navLogoUrl} alt={theme.appTitle} />
          </Container>
        </Navbar.Brand>
        <Nav className="ms-4 me-auto header-title">{theme.appTitle}</Nav>
        <Navbar.Collapse className="justify-content-end">
          <Nav className="px-3" navbar>
            <DropdownButton
              focusFirstItemOnShow={false}
              align="end"
              className="ps-3"
              variant="dark"
              id="dropdown-item-button"
              title={`${session?.user.username}`}>
              <Dropdown.Item href="/cambiar-password" onClick={changePasswordRedirect} as="button">
                <FontAwesomeIcon icon={faKey} fixedWidth className="me-1" />
                Cambiar Password
              </Dropdown.Item>
              <Dropdown.Item href="/logout" onClick={logout} as="button">
                <FontAwesomeIcon icon={faPowerOff} fixedWidth className="me-1" />
                Salir
              </Dropdown.Item>
            </DropdownButton>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Container fluid>
        <Row>
          <SideMenu />
          <main
            id="main-content"
            className="col-md-10 ms-sm-auto col-lg-10 px-4 pb-4 vh-100 overflow-y-auto"
            role="main">
            <Routes>
              <Route path="/cambiar-password" element={<PasswordChange />} />
              {orderRoutes()}
              {puntoEntregaRoutes()}
              {/* Products */}
              {productRoutes()}
              {marcaRoutes()}
              {productCategory1Routes()}
              {productCategory2Routes()}
              {productCategory3Routes()}
              {unitRoutes()}
              {/* Clientes */}
              {clienteRoutes()}
              {conversationRoutes()}
              {zonaRoutes()}
              {listaPrecioRoutes()}
              {/* {precioModificadorRoutes()} */}
              {clienteTipoRoutes()}
              {vendedorRoutes()}
              {companyRoutes()}
              {usuarioRoutes()}
              {/* Sync routes */}
              {connectorRoutes()}
              <Route path="/" element={<Navigate to="/pedidos" />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </main>
        </Row>
      </Container>
    </>
  );
}
