import React, { Fragment } from 'react';
import axios, { AxiosError } from 'axios';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Col,
  Form,
  Modal,
  Row,
  Container,
  Tabs,
  Tab,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  faEdit,
  faTimes,
  faEye,
  faUpload,
  faShareSquare,
  faRobot,
  faCheck,
  faFileInvoice,
  faBullhorn,
  faFileArrowDown,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { withToastManager } from 'react-toast-notifications';
import { AudioRecorder } from 'react-audio-voice-recorder';
import { saveAs } from 'file-saver';
import { DataTable, FormSelectField, FormInputField } from '../../components';
import { StatusCode, SourceChannel } from '../../constants';
import { APIAIInterpret } from '../../types/api';
import APIClient from '../../services/APIClient';
import UIUtils, { QueryParameters } from '../UIUtils';
import { WithRouterProps, withRouter } from '../withRouter';
import OrderFilters from '../../components/OrderFilters';
import { Order, OrderStatus } from '../../types/model';
import apiClient from '../../services/APIClient';
import config from '../../config';
import { withDocumentTitle } from '../withDocumentTitle';

interface OrderListState {
  entities: any[];
  entityToCancel: any;
  orderIdToChangeOrderStatus: number | null;
  orderStatusSelectedOption: string | undefined;
  orderStatusList: OrderStatus[];
  uploadedFile: any;
  fileContentText: any;
  orderInterpretMessage: string | null;
  orderInterpretAudioBase64: string | null;
  orderInterpretAudioMimeType: string | null;
  orderInterpretFileBase64: string | null;
  orderInterpretFileMimeType: string | null;
  orderInterpretPhoneNumber: string | null;
  orderInterpretEmail?: string | null;
  orderInterpretFullname?: string | null;
  orderInterpretAudioUrl: string | null;
  orderInterpretFileUrl: string | null;
  orderInterpretInputMode: 'text' | 'audio' | 'file';
  isDataLoading: boolean;
  isFileUploading: boolean;
  isInterpretingOrder: boolean;
  showAnularModal: boolean;
  showCancelMassiveOrdersModal: boolean;
  showConfirmMassiveOrdersModal: boolean;
  showChangeMassiveOrderStatusModal: boolean;
  showChangeOrderStatusModal: boolean;
  totalSize: number;
  limit: number;
  offset: number;
  showUploadModal: boolean;
  showInterpretOrderModal: boolean;
  fileFormatCode: string | null;
  isConfirmingOrder: boolean;
  showSendToERPModal: boolean;
  entityToSend: any;
  isLoading: boolean;
  sortPagingQuery: QueryParameters;
  disableButtonsFilter: boolean;
  filterStringState: string | undefined;
  orderFilterQuery: string;
  selectedRows: Order[];
}

enum OrderInterpretTabs {
  text = 'ai-interpret-text-tab',
  audio = 'ai-interpret-audio-tab',
  file = 'ai-interpret-file-tab',
}

class PedidoList extends React.Component<WithRouterProps, OrderListState> {
  static propTypes = {
    toastManager: PropTypes.object.isRequired,
  };
  apiParams: string = '';

  constructor(props: WithRouterProps) {
    super(props);
    this.state = {
      entities: [],
      entityToCancel: {},
      orderIdToChangeOrderStatus: null,
      orderStatusSelectedOption: undefined,
      orderStatusList: [],
      uploadedFile: null,
      fileContentText: null,
      orderInterpretMessage: null,
      orderInterpretAudioBase64: null,
      orderInterpretAudioMimeType: null,
      orderInterpretAudioUrl: null,
      orderInterpretFileBase64: null,
      orderInterpretFileMimeType: null,
      orderInterpretFileUrl: null,
      orderInterpretEmail: null,
      orderInterpretFullname: null,
      orderInterpretPhoneNumber: null,
      orderInterpretInputMode: 'text',
      isDataLoading: true,
      isFileUploading: false,
      isInterpretingOrder: false,
      showAnularModal: false,
      showCancelMassiveOrdersModal: false,
      showConfirmMassiveOrdersModal: false,
      showChangeOrderStatusModal: false,
      showChangeMassiveOrderStatusModal: false,
      totalSize: 0,
      limit: 10,
      offset: 0,
      showUploadModal: false,
      showInterpretOrderModal: false,
      fileFormatCode: null,
      isConfirmingOrder: false,
      showSendToERPModal: false,
      entityToSend: {},
      isLoading: false,
      sortPagingQuery: {
        pagination: { limit: 10, offset: 0 },
        sorting: { field: 'createdAt', direction: 'desc' },
      },
      disableButtonsFilter: true,
      filterStringState: undefined,
      orderFilterQuery: 'sortField=createdAt&sortDir=desc',
      selectedRows: [],
    };

    this.loadOrders = this.loadOrders.bind(this);
    this.handleUploadAudioChange = this.handleUploadAudioChange.bind(this);
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(this.props.location.search);
    const filterStringParams = urlParams.toString();
    if (filterStringParams) {
      this.loadOrders(filterStringParams);
    } else {
      this.loadOrders();
    }

    // retrieving order status list from db
    APIClient.get(`/order-status`)
      .then((response) => {
        const localOrderStatusList: OrderStatus[] = response.data.data;
        this.setState({ orderStatusList: localOrderStatusList });
      })
      .catch((error) => {
        this.setState({ orderStatusList: [] });
      });
  }

  async loadOrders(filterStringParams?: string, queryParameters?: QueryParameters) {
    const { toastManager } = this.props;
    const { limit, offset, filterStringState } = this.state;
    this.setState({ isDataLoading: true });
    try {
      let limitQuery = limit;
      let offsetQuery = offset;
      let sortDirection;
      let sortField;
      let filterString = filterStringParams;
      if (queryParameters) {
        limitQuery = queryParameters.pagination.limit;
        offsetQuery = queryParameters.pagination.offset;
        sortDirection = queryParameters.sorting.direction;
        sortField = queryParameters.sorting.field;
        filterString = filterStringState;
      }

      this.apiParams = `includeCantidad=true&sortField=${
        sortField ? sortField : 'createdAt'
      }&sortDir=${sortDirection ? sortDirection : 'desc'}`;
      const ordersRes = await APIClient.get(
        `/orders?limit=${limitQuery || '10'}&offset=${offsetQuery || '0'}&${
          this.apiParams
        }&${filterString}`,
      );
      const orders = ordersRes.data.data;
      this.setState({
        entities: orders,
        totalSize: ordersRes.data.meta.total,
        filterStringState: filterString,
        limit: limitQuery,
        offset: offsetQuery,
        orderFilterQuery: `limit=${limitQuery || '10'}&offset=${offsetQuery || '0'}&${
          this.apiParams
        }&${filterString}`,
      });
    } catch (error) {
      console.error('Cargando órdenes', error);
      toastManager.add(`Ocurrió un error: "${(error as Error).message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isDataLoading: false,
      });
    }
  }

  orderStatusWorkflow: Record<string, string[]> = {
    // current state: possibles states
    [StatusCode.cancelled]: [],
    [StatusCode.draft]: [StatusCode.confirmed, StatusCode.pending, StatusCode.cancelled],
    [StatusCode.pending]: [StatusCode.confirmed, StatusCode.cancelled],
    [StatusCode.confirmed]: [StatusCode.inPreparation],
    [StatusCode.inPreparation]: [
      StatusCode.delivered,
      StatusCode.deliveredPartial,
      StatusCode.nonDelivered,
      StatusCode.invoiced,
    ],
    [StatusCode.nonDelivered]: [StatusCode.delivered, StatusCode.deliveredPartial],
    [StatusCode.deliveredPartial]: [StatusCode.delivered],
    [StatusCode.delivered]: [StatusCode.invoiced],
    [StatusCode.invoiced]: [StatusCode.delivered, StatusCode.deliveredPartial],
  };

  handleFilterSubmitQueryString = (filterStringParams: string) => {
    const filterString = `?${filterStringParams}`;
    window.history.pushState({ path: filterString }, '', filterString);
    this.loadOrders(filterStringParams);
  };

  handleTableUpdate = async (queryParameters: QueryParameters) => {
    const { toastManager } = this.props;
    const { filterStringState } = this.state;
    try {
      await this.loadOrders(filterStringState, queryParameters);
    } catch (error) {
      toastManager.add(`Ocurrió un error: "${(error as Error).message}"`, {
        appearance: 'error',
      });
    }
  };

  sendToERP = async () => {
    const { entityToSend } = this.state;
    const { toastManager } = this.props;
    this.setState({ isLoading: true });
    try {
      /* TODO */
      // llamada para enviar la solicitud al ERP
      await APIClient.patch(`/orders/${entityToSend.id}`, {
        estadoOrderCodigo: StatusCode.inPreparation,
      });
      toastManager.add('El pedido se envió correctamente.', {
        appearance: 'success',
      });
    } catch (err) {
      console.error('Ocurrio un error al enviar el pedido al ERP.', err);
      toastManager.add(`Ocurrió un error: "${(err as Error).message}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({
        isLoading: false,
        entityToSend: {},
        showSendToERPModal: false,
      });
      await this.loadOrders();
    }
  };

  handleConfirmOrder = async (id?: number) => {
    const { isConfirmingOrder, filterStringState } = this.state;
    const { toastManager } = this.props;
    this.setState({ isLoading: true });

    if (isConfirmingOrder) {
      return;
    }

    try {
      this.setState({ isConfirmingOrder: true });

      await APIClient.patch(`/orders/${id}/change-status`, {
        estadoOrderCodigo: StatusCode.confirmed,
      });

      toastManager.add('Pedido verificado con exito', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.data.message) {
        toastManager.add(`Hubo un error al confirmar el pedido: ${error.response?.data.message}`, {
          appearance: 'warning',
        });
      } else {
        toastManager.add(`Hubo un error al confirmar el pedido: ${error}`, {
          appearance: 'warning',
        });
      }
    } finally {
      this.setState({ isConfirmingOrder: false, isLoading: false });
      await this.loadOrders(filterStringState);
    }
  };

  handleChangeOrderStatusClick = (row: number) => {
    this.setState({ showChangeOrderStatusModal: true, orderIdToChangeOrderStatus: row });
  };

  handleOrderStatusChange = (event) => {
    this.setState({ orderStatusSelectedOption: event.target.value });
  };

  handleOrderChangeStatusButtonClick = async () => {
    const { toastManager } = this.props;
    const { orderIdToChangeOrderStatus, orderStatusSelectedOption, isLoading, entities } =
      this.state;

    if (isLoading) {
      return;
    }

    if (!orderStatusSelectedOption) {
      toastManager.add(`No se seleccionó nuevo estado.`, {
        appearance: 'warning',
        autoDismiss: true,
      });
      return;
    }

    // disable buttons while loading
    this.setState({ isLoading: true });

    const orderCode = entities.filter((entity) => entity.id === orderIdToChangeOrderStatus)[0]
      .codigo;

    try {
      const updatedOrder = (
        await apiClient.patch(`/orders/${orderIdToChangeOrderStatus}/change-status`, {
          estadoOrderCodigo: orderStatusSelectedOption,
        })
      ).data.data;

      toastManager.add(`Pedido ${orderCode} actualizado correctamente.`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      console.error('No se pudo actualizar el estado del pedido.', error);
      toastManager.add(
        `No se pudo actualizar el estado del pedido ${orderCode}. ${(error as Error).message}"`,
        {
          appearance: 'error',
          autoDismiss: true,
        },
      );
    }

    this.setState({
      showChangeOrderStatusModal: false,
      orderIdToChangeOrderStatus: null,
      orderStatusSelectedOption: undefined,
      isLoading: false,
    });
    await this.loadOrders();
  };

  handleExportItemsClick = async (orderId) => {
    const { toastManager } = this.props;

    try {
      const response = await APIClient.downloadFile(
        `${config.api.url}/orders/${orderId}/export-order-items.xlsx`,
      );
      saveAs(response.data, `items_orden_${orderId}.xlsx`);

      toastManager.add(`Reporte items_orden_${orderId}.xlsx descargado correctamente.`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err) {
      console.error('Error descargando el reporte de items.', err);
      toastManager.add('Hubo un error al descargar el reporte de items.', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  handleAnularClick = (row) => {
    this.setState({ showAnularModal: true, entityToCancel: row });
  };

  handleSendToERPClick = (row) => {
    this.setState({ showSendToERPModal: true, entityToSend: row });
  };

  handleFileChange = (e) => {
    const newFile = e.target.files[0];
    this.setState({ uploadedFile: newFile });
  };

  handleRowSelect = (row, isSelected: boolean) => {
    this.setState((prevState) => {
      const selectedRows = isSelected
        ? [...prevState.selectedRows, row]
        : prevState.selectedRows.filter((selectedRow) => selectedRow.id !== row.id);
      return { selectedRows };
    });
  };

  handleSelectAll = (isSelected: boolean, rows) => {
    const { selectedRows } = this.state;
    const currentPageRowIds = rows.map((row) => row.id);

    const updatedSelectedRows = isSelected
      ? [...selectedRows, ...rows]
      : selectedRows.filter((row) => !currentPageRowIds.includes(row.id));
    this.setState({ selectedRows: updatedSelectedRows });
  };

  handleCancelMassiveOrders = async () => {
    const { selectedRows, filterStringState } = this.state;
    const { toastManager } = this.props;
    this.setState({ isLoading: true });

    try {
      const updatedSelectedRows = await APIClient.patch(`/orders/change-status`, {
        estadoOrderCodigo: StatusCode.cancelled,
        orderIds: selectedRows.map((row) => row.id),
      });

      this.setState({
        selectedRows: updatedSelectedRows.data.data,
      });

      toastManager.add('Sus pedidos fueron anulados correctamente.', {
        appearance: 'success',
      });
    } catch (error) {
      this.handleMassiveOrdersErrors(error);
    } finally {
      this.setState({
        isLoading: false,
        showCancelMassiveOrdersModal: false,
      });
      await this.loadOrders(filterStringState);
    }
  };

  handleChangeMassiveOrderStatus = async () => {
    const { filterStringState, selectedRows, orderStatusSelectedOption } = this.state;
    const { toastManager } = this.props;
    this.setState({ isLoading: true });

    try {
      const updatedSelectedRows = await APIClient.patch(`/orders/change-status`, {
        estadoOrderCodigo: orderStatusSelectedOption,
        orderIds: selectedRows.map((row) => row.id),
      });

      this.setState({
        selectedRows: updatedSelectedRows.data.data,
      });

      toastManager.add(`Cambio de estado en pedidos procesado con exito`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      this.handleMassiveOrdersErrors(error);
    } finally {
      this.setState({ showChangeMassiveOrderStatusModal: false, isLoading: false });
      await this.loadOrders(filterStringState);
    }
  };

  handleConfirmMassiveOrders = async () => {
    const { filterStringState, selectedRows } = this.state;
    const { toastManager } = this.props;
    this.setState({ isLoading: true });

    try {
      const updatedSelectedRows = await APIClient.patch(`/orders/change-status`, {
        estadoOrderCodigo: StatusCode.confirmed,
        orderIds: selectedRows.map((row) => row.id),
      });

      this.setState({
        selectedRows: updatedSelectedRows.data.data,
      });

      toastManager.add(`Pedidos verificados con exito`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (error) {
      this.handleMassiveOrdersErrors(error);
    } finally {
      this.setState({ showConfirmMassiveOrdersModal: false, isLoading: false });
      await this.loadOrders(filterStringState);
    }
  };

  handleMassiveOrdersErrors(error: unknown) {
    const { toastManager } = this.props;
    let errorMessage: string | null = null;
    let errorMessagesByOrder: string[] | null = null;

    // if it's an Axios error
    if (axios.isAxiosError(error) && error?.response) {
      const responseData = error.response?.data?.data;

      // if it's an Axios error and has data and it's an array
      if (responseData && Array.isArray(responseData)) {
        errorMessagesByOrder = responseData;
      } else {
        // if it's an Axios error and has data but it's not an array
        errorMessage = responseData ?? error.message;
      }
    } else {
      // if it's not an Axios error
      errorMessage = (error as Error).message;
    }

    // if it's an Axios error and it's an array
    if (errorMessagesByOrder) {
      errorMessagesByOrder.forEach((err: string) => {
        toastManager.add(`Ocurrieron errores: "${err.trim()}"`, {
          appearance: 'error',
        });
      });
    } else if (errorMessage) {
      // if it's an Axios error and not an array
      // if it's not an Axios error
      toastManager.add(`Ocurrió un error: "${errorMessage.trim()}"`, {
        appearance: 'error',
      });
    }
  }

  handleCancelMassiveOrdersClick = () => {
    this.setState({ showCancelMassiveOrdersModal: true });
  };

  handleChangeMassiveOrdersClick = () => {
    this.setState({ showChangeMassiveOrderStatusModal: true });
  };

  handleConfirMassiveOrdersClick = () => {
    this.setState({ showConfirmMassiveOrdersModal: true });
  };

  // #region Order Interpret (AI)

  handleOrderInterpretInputCleanse = () => {
    this.setState({
      showInterpretOrderModal: false,
      orderInterpretMessage: null,
      orderInterpretFullname: null,
      orderInterpretEmail: null,
      orderInterpretPhoneNumber: null,
    });
  };

  handleOrderInterpretMessageChange = (e) => {
    if (e.target.value === '') {
      this.setState({ orderInterpretMessage: null });
    } else {
      this.setState({ orderInterpretMessage: e.target.value });
    }
  };

  handleOrderInterpretFullnameChange = (e) => {
    if (e.target.value === '') {
      this.setState({ orderInterpretFullname: null });
    } else {
      this.setState({ orderInterpretFullname: e.target.value });
    }
  };

  handleOrderInterpretEmailChange = (e) => {
    this.setState({ orderInterpretEmail: e.target.value });
  };

  handleOrderInterpretPhoneNumberChange = (e) => {
    this.setState({ orderInterpretPhoneNumber: e.target.value });
  };

  handleInterpretAudioRecordingComplete = async (blob: Blob) => {
    // the audioUrl is for displaying in the browser
    const audioUrl = URL.createObjectURL(blob);
    // the base64 and mimeType are for sending to the API
    const audioBase64 = await UIUtils.getBase64FromBlob(blob);
    let audioMimeType = blob.type;
    if (blob.type.indexOf(';') > 0) {
      audioMimeType = blob.type.substring(0, blob.type.indexOf(';'));
    }

    this.setState({
      orderInterpretAudioUrl: audioUrl,
      orderInterpretAudioBase64: audioBase64,
      orderInterpretAudioMimeType: audioMimeType,
    });
  };

  async handleUploadAudioChange(event: any) {
    const { toastManager } = this.props;
    const audio = event.target.files[0];

    if (audio) {
      if (audio.size > 5000000) {
        toastManager.add('Solo se permiten audios de menos de 5 MB.', {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        const audioUrl = URL.createObjectURL(audio);
        const audioBase64 = await UIUtils.getBase64FromBlob(audio);
        let audioMimeType = audio.type;
        if (audio.type.indexOf(';') > 0) {
          audioMimeType = audio.type.substring(0, audio.type.indexOf(';'));
        }
        this.setState({
          orderInterpretAudioUrl: audioUrl,
          orderInterpretAudioBase64: audioBase64,
          orderInterpretAudioMimeType: audioMimeType,
        });
      }
    }
  }

  handleUploadFileChange = async (event: any) => {
    const { toastManager } = this.props;
    const file = event.target.files[0];

    if (file) {
      if (file.size > 10_000_000) {
        toastManager.add('Solo se permiten archivos de menos de 10 MB.', {
          appearance: 'error',
          autoDismiss: true,
        });
      } else {
        const fileUrl = URL.createObjectURL(file);
        const fileBase64 = await UIUtils.getBase64FromBlob(file);
        let fileMimeType = file.type;
        if (file.type.indexOf(';') > 0) {
          fileMimeType = file.type.substring(0, file.type.indexOf(';'));
        }
        this.setState({
          orderInterpretFileUrl: fileUrl,
          orderInterpretFileBase64: fileBase64,
          orderInterpretFileMimeType: fileMimeType,
        });
      }
    }
  };

  handleOrderInterpretInputTabChange = (key) => {
    switch (key) {
      case OrderInterpretTabs.text:
        this.setState({ orderInterpretInputMode: 'text' });
        break;
      case OrderInterpretTabs.audio:
        this.setState({ orderInterpretInputMode: 'audio' });
        break;
      case OrderInterpretTabs.file:
        this.setState({ orderInterpretInputMode: 'file' });
        break;
      default:
        this.setState({ orderInterpretInputMode: 'text' });
        break;
    }
  };

  handleClickInterpretOrderByAI = async () => {
    this.setState({ isInterpretingOrder: true });

    const { toastManager, navigate } = this.props;
    const {
      orderInterpretInputMode,
      orderInterpretMessage,
      orderInterpretAudioBase64,
      orderInterpretAudioMimeType,
      orderInterpretFileBase64,
      orderInterpretFileMimeType,
      orderInterpretPhoneNumber,
      orderInterpretEmail,
      orderInterpretFullname,
    } = this.state;
    try {
      if (!orderInterpretFullname && !orderInterpretEmail && !orderInterpretPhoneNumber) {
        toastManager.add('Por favor específica algún dato de identificación del cliente', {
          appearance: 'warning',
        });
        return;
      }
      const requestBody: APIAIInterpret = {
        sourceChannel: SourceChannel.backOffice,
        fullname: orderInterpretFullname,
        email: orderInterpretEmail,
        phoneNumber: orderInterpretPhoneNumber,
      };
      // pass only the relevant data
      if (orderInterpretInputMode == 'text') {
        requestBody.message = orderInterpretMessage;
      } else if (orderInterpretInputMode == 'audio') {
        requestBody.audio = orderInterpretAudioBase64;
        requestBody.audioMimeType = orderInterpretAudioMimeType;
      } else if (orderInterpretInputMode == 'file') {
        requestBody.file = orderInterpretFileBase64;
        requestBody.fileMimeType = orderInterpretFileMimeType;
      }
      const aiGeneratedOrderResponse = await APIClient.post('/orders/interpret', requestBody);
      const orderId = aiGeneratedOrderResponse.data.data;
      navigate && navigate(`/orders/${orderId}`);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        const responseError = error.response;
        const status = responseError?.data.status;
        const message = responseError?.data.message;

        toastManager.add(`${message}`, {
          appearance: status === 409 ? 'warning' : 'error',
        });
      } else {
        toastManager.add(
          `Hubo un error al querer mostrar la orden interpretada: ${(error as Error).message}`,
          {
            appearance: 'error',
          },
        );
      }
    } finally {
      this.setState({ isInterpretingOrder: false });
    }
  };

  // #endregion Order Interpret (AI)

  handleUploadFormChange = (e) => {
    const { id, fileFormatCode, value } = e.target;
    if (value === '') {
      this.setState({ [id]: null as unknown } as Pick<OrderListState, keyof OrderListState>);
    } else if (id === 'fileFormatCode' && value !== fileFormatCode) {
      this.setState({ fileFormatCode: value, uploadedFile: null, fileContentText: null });
    } else {
      this.setState({ [id]: value as unknown } as Pick<OrderListState, keyof OrderListState>);
    }
  };

  anularSolicitud = async () => {
    const { entityToCancel } = this.state;
    const { toastManager } = this.props;
    this.setState({ isLoading: true });
    try {
      await APIClient.patch(`/orders/${entityToCancel.id}`, {
        estadoOrderCodigo: StatusCode.cancelled,
      });
      toastManager.add('Pedido anulado correctamente.', {
        appearance: 'success',
      });
      this.setState((prevState) => {
        const { entities } = prevState;
        const cancelledEntity = entities.find((ent) => ent.id === entityToCancel.id);
        cancelledEntity.estadoOrderCodigo = StatusCode.cancelled;
        return { entities, isLoading: false };
      });
    } catch (error) {
      let errorMessage =
        axios.isAxiosError(error) && error.response
          ? error.response?.data.message
          : (error as Error).message;
      toastManager.add(`Ocurrió un error: "${errorMessage}"`, {
        appearance: 'error',
      });
    } finally {
      this.setState({ isLoading: false, showAnularModal: false, entityToCancel: {} });
      await this.loadOrders();
    }
  };

  showSolicitudUploadModal = () => {
    this.setState({ showUploadModal: true });
  };

  showInterpretOrderModal = () => {
    this.setState({ showInterpretOrderModal: true });
  };

  shouldDisableUploadButton = () => {
    const { isFileUploading, fileContentText, fileFormatCode, uploadedFile } = this.state;
    if (isFileUploading) {
      return true;
    }
    if ((fileContentText || uploadedFile) && fileFormatCode) {
      return false;
    }
    return true;
  };

  uploadFile = async () => {
    const { toastManager } = this.props;
    const { uploadedFile, fileFormatCode, fileContentText } = this.state;

    const fileBlob = new Blob([uploadedFile], { type: '.txt, .csv' });
    this.setState({ isFileUploading: true });
    try {
      let text = '';
      if (fileContentText && fileContentText.length > 0) {
        text = fileContentText;
      } else {
        text = await fileBlob.text();
      }
      if (text.length === 0) {
        toastManager.add('No se ha procesado el pedido. Error: No hay texto que procesar.', {
          appearance: 'error',
        });
        return false;
      }
      await APIClient.post('/orders/cargar', {
        fileContent: text,
        fileFormatCode,
      });
      toastManager.add('Se ha creado el nuevo pedido correctamente.', {
        appearance: 'success',
        autoDismiss: true,
      });
      this.loadOrders();
    } catch (error) {
      toastManager.add(
        `Ocurrió un error al subir el archivo: ${
          axios.isAxiosError(error) && error.response
            ? error.response?.data.message
            : (error as Error).message
        }`,
        {
          appearance: 'error',
        },
      );
    } finally {
      this.setState({
        fileContentText: null,
        isFileUploading: false,
        fileFormatCode: null,
        showUploadModal: false,
        uploadedFile: null,
      });
    }
  };

  // #region RENDERS
  renderAnularPedidoModal = () => {
    const { isLoading, showAnularModal } = this.state;
    return (
      <Modal show={showAnularModal} onHide={() => this.setState({ showAnularModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Anular Pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea anular este pedido?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isLoading} variant="primary" onClick={this.anularSolicitud}>
            {isLoading ? (
              <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="me-1" />
            ) : (
              'Anular'
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => this.setState({ showAnularModal: false, entityToCancel: {} })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderCancelMassiveOrdersModal = () => {
    const { isLoading, showCancelMassiveOrdersModal, selectedRows } = this.state;
    return (
      <Modal
        show={showCancelMassiveOrdersModal}
        onHide={() => this.setState({ showCancelMassiveOrdersModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Anular Pedidos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea anular los pedido seleccionados?</p>
          <div>
            {selectedRows.map((order) => (
              <div key={order.id}>
                {order.codigo} - {order.Cliente && order.Cliente.razonSocial}{' '}
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isLoading} variant="primary" onClick={this.handleCancelMassiveOrders}>
            {isLoading ? (
              <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="me-1" />
            ) : (
              'Anular'
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => this.setState({ showCancelMassiveOrdersModal: false })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderConfimMassiveOrdersModal = () => {
    const { isLoading, showConfirmMassiveOrdersModal, selectedRows } = this.state;
    return (
      <Modal
        show={showConfirmMassiveOrdersModal}
        onHide={() => this.setState({ showConfirmMassiveOrdersModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Pedidos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea confirmar los pedido seleccionados?</p>
          <div>
            {selectedRows.map((order) => (
              <div key={order.id}>
                {order.codigo} - {order.Cliente && order.Cliente.razonSocial}{' '}
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant="primary"
            onClick={() => this.handleConfirmMassiveOrders()}>
            {isLoading ? (
              <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="me-1" />
            ) : (
              'Confirmar'
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => this.setState({ showConfirmMassiveOrdersModal: false })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderSendToERPModal = () => {
    const { isLoading, showSendToERPModal } = this.state;
    return (
      <Modal show={showSendToERPModal} onHide={() => this.setState({ showSendToERPModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Enviar pedido a ERP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro que deseas enviar el pedido al ERP? Una vez enviado no podrás editarlo</p>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isLoading} variant="primary" onClick={this.sendToERP}>
            {isLoading ? (
              <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="me-1" />
            ) : (
              'Enviar'
            )}
          </Button>
          <Button
            disabled={isLoading}
            variant="secondary"
            onClick={() => this.setState({ showSendToERPModal: false, entityToSend: {} })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderSolicitudUploadModal = () => {
    const { fileContentText, showUploadModal, isFileUploading, uploadedFile, fileFormatCode } =
      this.state;

    const validFormats = [
      { codigo: 'nestle', descripcion: 'Nestle Argentina' },
      { codigo: 'grupo_familia', descripcion: 'Grupo Familia' },
    ];

    return (
      <Modal
        size="lg"
        show={showUploadModal}
        onHide={() => this.setState({ showUploadModal: false, uploadedFile: null })}>
        <Modal.Header>
          <Modal.Title>Creación de Pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Col>
              <FormSelectField
                id="fileFormatCode"
                label="Formato"
                choices={validFormats}
                choiceIdField="codigo"
                choiceLabelField="descripcion"
                disabled={isFileUploading}
                onChange={this.handleUploadFormChange}
              />
            </Col>
            {fileFormatCode === 'nestle' && (
              <Row className="justify-content-center">
                <Col md={11}>
                  <Form.Group className="d-flex align-items-center">
                    {this.renderFileLabel()}
                    <input
                      name="uploadedFile"
                      className="form-control custom-file-input custom-upload-label-position"
                      id="uploadedFile"
                      lang="es"
                      data-browse="Seleccionar"
                      type="file"
                      accept=".txt, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      onChange={this.handleFileChange}
                      disabled={fileContentText || isFileUploading}
                    />
                  </Form.Group>
                </Col>
              </Row>
            )}
            {fileFormatCode === 'grupo_familia' && (
              <Col>
                <FormInputField
                  id="fileContentText"
                  label="Ingrese el contenido del texto:"
                  as="textarea"
                  placeholder="Contenido del email en formato tabla..."
                  onChange={this.handleUploadFormChange}
                  disabled={uploadedFile || isFileUploading}
                />
              </Col>
            )}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={this.shouldDisableUploadButton()}
            variant="primary"
            onClick={this.uploadFile}>
            {isFileUploading ? (
              <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="me-1 p-0" />
            ) : (
              'Cargar'
            )}
          </Button>
          <Button
            disabled={isFileUploading}
            variant="secondary"
            onClick={() => this.setState({ showUploadModal: false, uploadedFile: null })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderChangeMassiveOrderStatusModal = () => {
    const {
      isLoading,
      showChangeMassiveOrderStatusModal,
      selectedRows,
      orderStatusSelectedOption,
      orderStatusList,
    } = this.state;

    if (selectedRows.length === 0) {
      return;
    }

    const orderStatusListFiltered = orderStatusList.filter((orderStatus) =>
      selectedRows.every((order) =>
        this.orderStatusWorkflow[order.estadoOrderCodigo].includes(orderStatus.codigo),
      ),
    );

    const noCommonStatus = orderStatusListFiltered.length === 0;

    return (
      <Modal
        show={showChangeMassiveOrderStatusModal}
        onHide={() => this.setState({ showChangeMassiveOrderStatusModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Actualizar los estados de los pedidos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea actualizar los estados de los pedido seleccionados?</p>
          <div>
            {selectedRows.map((order) => (
              <Fragment key={order.id}>
                <div key={order.id}>
                  {order.codigo} - {order.Cliente && order.Cliente.razonSocial}{' '}
                </div>
                <span>
                  Pedido <strong>{order.codigo}</strong>.
                </span>
                <p>Estado actual: {UIUtils.getSolicitudEstadoBadge(order.estadoOrderCodigo)}</p>
              </Fragment>
            ))}
          </div>
          {noCommonStatus ? (
            <p className="text-danger">
              Las órdenes seleccionadas no poseen un estado común disponible para actualizar.
            </p>
          ) : (
            <select
              id="orderStatus"
              className="form-select"
              value={orderStatusSelectedOption}
              onChange={this.handleOrderStatusChange}
              disabled={orderStatusListFiltered.length === 0}>
              <option value={''}>(Seleccione estado)</option>
              {((orderStatusListFiltered || []) as OrderStatus[]).map((orderStatus) => (
                <option key={Math.random()} value={orderStatus?.codigo}>
                  {orderStatus?.descripcion}
                </option>
              ))}
            </select>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading || !orderStatusSelectedOption || noCommonStatus}
            variant="primary"
            onClick={() => this.handleChangeMassiveOrderStatus()}>
            {isLoading ? (
              <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="me-1" />
            ) : (
              'Confirmar'
            )}
          </Button>
          <Button
            variant="secondary"
            onClick={() => this.setState({ showChangeMassiveOrderStatusModal: false })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderChangeOrderStatusModal = () => {
    const {
      showChangeOrderStatusModal,
      orderIdToChangeOrderStatus,
      orderStatusSelectedOption,
      isLoading,
      orderStatusList,
      entities,
    } = this.state;

    if (orderIdToChangeOrderStatus === null) {
      return;
    }

    const currentOrderStatusCode = entities.filter(
      (entity) => entity.id === orderIdToChangeOrderStatus,
    )[0].estadoOrderCodigo;

    const currentOrderStatusDescription = orderStatusList.filter(
      (orderStatus) => orderStatus.codigo === currentOrderStatusCode,
    )[0].descripcion;

    const orderStatusListFiltered = orderStatusList.filter((orderStatus) =>
      this.orderStatusWorkflow[currentOrderStatusCode].includes(orderStatus.codigo),
    );

    const orderCode = entities.filter((entity) => entity.id === orderIdToChangeOrderStatus)[0]
      .codigo;

    return (
      <Modal
        show={showChangeOrderStatusModal}
        onHide={() => !isLoading && this.setState({ showChangeOrderStatusModal: false })}>
        <Modal.Header closeButton>
          <Modal.Title>Actualizar estado de pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {orderStatusList.length > 0 ? (
            <>
              <span>
                Pedido <strong>{orderCode}</strong>.
              </span>
              {/* <p>Estado actual: <strong>{currentOrderStatusDescription}</strong></p> */}
              <p>Estado actual: {UIUtils.getSolicitudEstadoBadge(currentOrderStatusCode)}</p>
            </>
          ) : (
            <p>Cargando estados...</p>
          )}
          <select
            id="orderStatus"
            className="form-select"
            value={orderStatusSelectedOption}
            onChange={this.handleOrderStatusChange}>
            <option>(Seleccione estado)</option>
            {((orderStatusListFiltered || []) as OrderStatus[]).map((orderStatus) => (
              <option key={Math.random()} value={orderStatus?.codigo}>
                {orderStatus?.descripcion}
              </option>
            ))}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isLoading}
            variant="primary"
            onClick={this.handleOrderChangeStatusButtonClick}>
            {isLoading ? (
              <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin className="me-1" />
            ) : (
              'Actualizar'
            )}
          </Button>
          <Button
            disabled={isLoading}
            variant="secondary"
            onClick={() => this.setState({ showChangeOrderStatusModal: false, entityToSend: {} })}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderInterpretOrderModal = () => {
    const {
      showInterpretOrderModal,
      isInterpretingOrder,
      orderInterpretInputMode,
      orderInterpretMessage,
      orderInterpretFullname,
      orderInterpretEmail,
      orderInterpretPhoneNumber,
      orderInterpretAudioUrl,
      orderInterpretFileUrl,
    } = this.state;

    const isValidEmail = orderInterpretEmail ? /^\S+@\S+\.\S+$/.test(orderInterpretEmail) : true;
    const isValidPhoneNumber = orderInterpretPhoneNumber
      ? /^[0-9 ()-]+$/.test(orderInterpretPhoneNumber)
      : true;

    const isInterpretButtonEnabled =
      ((orderInterpretInputMode == 'text' && orderInterpretMessage) ||
        (orderInterpretInputMode == 'audio' && orderInterpretAudioUrl) ||
        (orderInterpretInputMode == 'file' && orderInterpretFileUrl)) &&
      isValidEmail &&
      isValidPhoneNumber;

    return (
      <Modal
        size="lg"
        show={showInterpretOrderModal}
        onHide={() => this.setState({ showInterpretOrderModal: false })}>
        <Modal.Header>
          <Modal.Title>Interpretar orden</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Tabs
              defaultActiveKey={OrderInterpretTabs.text}
              id="ai-interpret-input-modes"
              onSelect={this.handleOrderInterpretInputTabChange}>
              <Tab eventKey={OrderInterpretTabs.text} title="Texto / Mensaje">
                <FormInputField
                  id="orderInterpretMessage"
                  as="textarea"
                  defaultValue={orderInterpretMessage || undefined}
                  style={{ height: '150px' }}
                  label="Texto del pedido a interpretar:"
                  placeholder="Ingresa el mensaje que te envía tu cliente mediante WhatsApp, e-mail, u otro medio"
                  onChange={this.handleOrderInterpretMessageChange}
                />
              </Tab>
              <Tab eventKey={OrderInterpretTabs.audio} title="Audio">
                <label className="form-label">
                  Graba o sube un audio con el pedido (toca en el ícono de guardar cuando
                  finalices).
                </label>
                <div className="d-flex mb-3">
                  <div className="form-group">
                    <AudioRecorder
                      onRecordingComplete={this.handleInterpretAudioRecordingComplete}
                      audioTrackConstraints={{
                        noiseSuppression: true,
                        echoCancellation: true,
                      }}
                      downloadFileExtension="webm"
                      showVisualizer
                    />
                  </div>

                  <div className="form-group ms-3">
                    <input
                      name="aiAudioUpload"
                      className="d-none"
                      id="aiAudioUploadInput"
                      type="file"
                      accept="audio/flac, audio/mp3, audio/mp4, audio/mpeg, audio/mpga, audio/m4a, audio/ogg, audio/wav, audio/webm"
                      onChange={this.handleUploadAudioChange}
                    />
                    <div className="audio-recorder" style={{ height: '40px' }}>
                      <label
                        htmlFor="aiAudioUploadInput"
                        style={{ width: '100%', height: '100%', cursor: 'pointer' }}>
                        <FontAwesomeIcon
                          icon={faUpload}
                          title="Subir audio"
                          size="4x"
                          style={{ width: '40px', marginTop: '12px' }}
                        />
                      </label>
                    </div>
                  </div>
                </div>
                {orderInterpretAudioUrl && (
                  <div className="form-group">
                    <label className="form-label" htmlFor="ai-interpret-input-audio-playback">
                      Audio a enviar:
                    </label>
                    <div>
                      <audio
                        id="ai-interpret-input-audio-playback"
                        src={orderInterpretAudioUrl}
                        controls
                      />
                    </div>
                  </div>
                )}
              </Tab>
              <Tab eventKey={OrderInterpretTabs.file} title="Archivo / Imagen">
                <label className="form-label">Sube el archivo que contiene tu pedido.</label>
                <div className="d-flex mb-3">
                  <Form.Group>
                    <Form.Control
                      name="aiFileUpload"
                      id="aiFileUploadInput"
                      type="file"
                      accept="application/pdf, text/plain, text/csv, application/json, application/xml, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, image/jpg, image/png"
                      onChange={this.handleUploadFileChange}
                    />
                    <small>
                      Formatos soportados: PDF, XLSX, XLS, DOCX, CSV, TXT, JSON, JPG, PNG.
                    </small>
                  </Form.Group>
                </div>
              </Tab>
            </Tabs>
            <br></br>
            <Row>
              <h5>Selecciona algún dato adicional:</h5>
            </Row>
            <br></br>
            <FormInputField
              id="orderInterpretFullname"
              as="input"
              label="Nombre / Razón Social:"
              placeholder=""
              defaultValue={orderInterpretFullname || undefined}
              onChange={this.handleOrderInterpretFullnameChange}
            />
            <Row>
              <Col>
                <FormInputField
                  id="orderInterpretEmail"
                  as="input"
                  label="E-mail:"
                  placeholder=""
                  defaultValue={orderInterpretEmail || undefined}
                  onChange={this.handleOrderInterpretEmailChange}
                />
                {!isValidEmail && (
                  <div className="alert alert-danger" role="alert">
                    E-mail inválido
                  </div>
                )}
              </Col>
              <Col>
                <FormInputField
                  id="orderInterpretPhoneNumber"
                  as="input"
                  label="Número de teléfono"
                  placeholder=""
                  defaultValue={orderInterpretPhoneNumber || undefined}
                  onChange={this.handleOrderInterpretPhoneNumberChange}
                />
                {!isValidPhoneNumber && (
                  <div className="alert alert-danger" role="alert">
                    Número inválido
                  </div>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={!isInterpretButtonEnabled}
            variant="primary"
            onClick={this.handleClickInterpretOrderByAI}>
            {isInterpretingOrder ? (
              <FontAwesomeIcon icon={faCircleNotch} spin fixedWidth className="me-1 p-0" />
            ) : (
              'Interpretar'
            )}
          </Button>
          <Button
            disabled={false}
            variant="secondary"
            onClick={this.handleOrderInterpretInputCleanse}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  renderFileLabel = () => {
    const { fileContentText, uploadedFile } = this.state;
    if (fileContentText) {
      return (
        <label
          id="override-boostrap"
          className="custom-file-label custom-upload-label-position custom-label-disabled"
          htmlFor="uploadedFile">
          Haga click para seleccionar un archivo.
        </label>
      );
    }
    if (uploadedFile) {
      return (
        <label className="custom-file-label custom-upload-label-position" htmlFor="uploadedFile">
          {uploadedFile.name}
        </label>
      );
    }
    return (
      <label className="custom-file-label custom-upload-label-position" htmlFor="uploadedFile">
        Haga click para seleccionar un archivo.
      </label>
    );
  };

  renderMultiselectionAction = (): JSX.Element => {
    const { selectedRows } = this.state;
    return (
      <div className="col-md-3 d-flex justify-content-between align-items-center ps-2 py-1 pe-1 bg-white border rounded">
        <div className="my-auto ms-2">Items Seleccionados: {selectedRows.length}</div>
        <ButtonToolbar className="d-flex justify-content-center align-items-center ml-2">
          <ButtonGroup>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => this.handleConfirMassiveOrdersClick()}
              title="Confirmar pedido"
              className="mr-2"
              disabled={selectedRows.length === 0}>
              <FontAwesomeIcon icon={faCheck} fixedWidth size="xs" />
            </Button>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => this.handleChangeMassiveOrdersClick()}
              title="Cambiar estado"
              className="mr-2"
              disabled={selectedRows.length === 0}>
              <FontAwesomeIcon icon={faFileInvoice} fixedWidth size="xs" />
            </Button>
            <Button
              size="sm"
              variant="outline-primary"
              onClick={() => this.handleCancelMassiveOrdersClick()}
              title="Anular"
              disabled={selectedRows.length === 0}>
              <FontAwesomeIcon icon={faTimes} fixedWidth size="xs" />
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    );
  };
  // #endregion RENDERS

  render() {
    const { entities, isDataLoading, totalSize, isConfirmingOrder, orderFilterQuery } = this.state;
    const columns = [
      {
        dataField: 'codigo',
        text: 'Código',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <span className="font-monospace">{cellContent}</span>
            <br />
            <small className="font-monospace">{row.erpCode}</small>
          </>
        ),
      },
      {
        dataField: 'Cliente.razonSocial',
        text: 'Cliente',
        sort: true,
        formatter: (cellContent, row) => (
          <>
            {cellContent}
            <br />
            <small>{row.Cliente && row.Cliente.documentoNumero}</small>
          </>
        ),
      },
      {
        dataField: 'Cliente.documentoNumero',
        text: 'CUIT',
        sort: false,
        hidden: true,
      },
      {
        dataField: 'createdAt',
        text: 'Fecha Alta',
        sort: true,
        formatter: (cellContent, row) => cellContent && <Moment interval={0}>{cellContent}</Moment>,
      },
      {
        dataField: 'fechaConfirmacion',
        text: 'Fecha Verificación',
        sort: true,
        formatter: (cellContent, row) => cellContent && <Moment interval={0}>{cellContent}</Moment>,
      },
      {
        dataField: 'estadoOrderCodigo',
        text: 'Estado',
        sort: true,
        csvFormatter: (cellContent) => {
          let nombre = '';
          switch (cellContent) {
            case StatusCode.draft:
              nombre = 'Borrador';
              break;
            case StatusCode.cancelled:
              nombre = 'Cancelado';
              break;
            case StatusCode.delivered:
              nombre = 'Cerrado';
              break;
            case StatusCode.confirmed:
              nombre = 'Enviado';
              break;
            case StatusCode.deliveredPartial:
              nombre = 'Verificado';
              break;
            case StatusCode.inPreparation:
              nombre = 'En preparación';
              break;
            case StatusCode.invoiced:
              nombre = 'Facturado';
              break;
            case StatusCode.pending:
              nombre = 'Pendiente';
              break;
            default:
              break;
          }
          return nombre;
        },
        formatter: (cellContent, row) => UIUtils.getSolicitudEstadoBadge(cellContent),
      },
      // {
      //   dataField: 'UsuarioPerfil.Usuario.firstName',
      //   text: 'Usuario',
      //   formatter: (cellContent, row) => {
      //     return row.UsuarioPerfil ? (
      //       `${cellContent} ${row.UsuarioPerfil?.Usuario?.lastName}`
      //     ) : (
      //       <em>OrderToB</em>
      //     );
      //   },
      // },
      {
        dataField: 'sourceChannel',
        isDummyField: true,
        text: 'Origen',
        formatter: (cellContent, row) => {
          let sourceChannel;
          switch (row.sourceChannel) {
            case SourceChannel.backOffice:
              sourceChannel = 'Central de Pedidos';
              break;
            case SourceChannel.portalWeb:
              sourceChannel = 'Portal Web';
              break;
            case SourceChannel.whatsapp:
              sourceChannel = 'WhatsApp';
              break;
            case SourceChannel.api:
              sourceChannel = 'API';
              break;
            case SourceChannel.integration:
              sourceChannel = 'Integración';
              break;
            case SourceChannel.email:
              sourceChannel = 'E-mail';
              break;
            default:
              break;
          }
          return (
            <>
              {sourceChannel} <br />
              {row.UsuarioPerfil ? (
                <small>
                  <FontAwesomeIcon icon={faUser} fixedWidth size="xs" />
                  {row.UsuarioPerfil?.Usuario?.firstName} {row.UsuarioPerfil?.Usuario?.lastName}
                </small>
              ) : null}
            </>
          );
        },
      },
      {
        dataField: 'actions',
        isDummyField: true,
        text: '',
        csvExport: false,
        formatter: (cellContent, row) => (
          <ButtonToolbar className="d-flex justify-content-center align-items-center">
            <ButtonGroup>
              {!(
                row.estadoOrderCodigo == StatusCode.pending ||
                row.estadoOrderCodigo == StatusCode.draft
              ) ? (
                <LinkContainer to={`/orders/${row.id}`}>
                  <Button size="sm" variant="outline-primary" title="Ver">
                    <FontAwesomeIcon icon={faEye} fixedWidth size="xs" />
                  </Button>
                </LinkContainer>
              ) : (
                <LinkContainer to={`/orders/${row.id}`}>
                  <Button size="sm" variant="outline-primary" title="Editar">
                    <FontAwesomeIcon icon={faEdit} fixedWidth size="xs" />
                  </Button>
                </LinkContainer>
              )}
              {/* <Button
                size="sm"
                disabled={!row.estadoOrderCodigo.match(new RegExp(StatusCode.confirmed))}
                variant="outline-primary"
                onClick={() => this.handleSendToERPClick(row)}
                title="Enviar a ERP">
                <FontAwesomeIcon icon={faShareSquare} fixedWidth size="xs" />
              </Button> */}
              <Button
                size="sm"
                disabled={
                  (row.estadoOrderCodigo !== StatusCode.pending &&
                    row.estadoOrderCodigo !== StatusCode.draft) ||
                  isConfirmingOrder
                }
                variant="outline-primary"
                onClick={() => this.handleConfirmOrder(row.id)}
                title="Confirmar pedido">
                <FontAwesomeIcon icon={faCheck} fixedWidth size="xs" />
              </Button>
              <Button
                size="sm"
                disabled={
                  row.estadoOrderCodigo === StatusCode.cancelled ||
                  row.estadoOrderCodigo === StatusCode.delivered
                }
                variant="outline-primary"
                onClick={() => this.handleChangeOrderStatusClick(row.id)}
                title="Cambiar estado">
                <FontAwesomeIcon icon={faFileInvoice} fixedWidth size="xs" />
              </Button>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => this.handleExportItemsClick(row.id)}
                title="Exportar ítems">
                <FontAwesomeIcon icon={faFileArrowDown} fixedWidth size="xs" />
              </Button>
              <Button
                size="sm"
                disabled={
                  !row.estadoOrderCodigo.match(
                    new RegExp(`${StatusCode.draft}|${StatusCode.pending}`),
                  )
                }
                variant="outline-primary"
                onClick={() => this.handleAnularClick(row)}
                title="Anular">
                <FontAwesomeIcon icon={faTimes} fixedWidth size="xs" />
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        ),
      },
    ];

    return (
      <div>
        <h1 className="page-title">Pedidos</h1>
        {this.renderAnularPedidoModal()}
        {this.renderSendToERPModal()}
        {this.renderSolicitudUploadModal()}
        {this.renderInterpretOrderModal()}
        {this.renderChangeOrderStatusModal()}
        {this.renderCancelMassiveOrdersModal()}
        {this.renderConfimMassiveOrdersModal()}
        {this.renderChangeMassiveOrderStatusModal()}

        <OrderFilters
          isDataLoading={isDataLoading}
          handleFilterSubmitQueryString={this.handleFilterSubmitQueryString}
        />
        <DataTable
          remote={{
            filter: true,
            pagination: true,
            sort: true,
            cellEdit: false,
          }}
          showSearch={false}
          columns={columns}
          data={entities}
          onTableUpdate={this.handleTableUpdate}
          isDataLoading={isDataLoading}
          totalSize={totalSize}
          keyField="id"
          exportConfig={{
            exportURL: `/orders/export.xlsx?${orderFilterQuery}`,
          }}
          //addButton={this.showSolicitudUploadModal}
          //buttonText="Importar"
          //buttonIcon={faUpload}
          onSecondButtonClick={this.showInterpretOrderModal}
          secondButtonText="Interpretar"
          secondButtonIcon={faRobot}
          secondButtonVariant="primary"
          defaultSorted={[{ dataField: 'createdAt', order: 'desc' }]}
          // Resources required in multiselection mode.
          renderMultiSelectionActions={this.renderMultiselectionAction()}
          selectRow={{
            mode: 'checkbox',
            clickToSelect: true,
            onSelect: this.handleRowSelect,
            onSelectAll: this.handleSelectAll,
          }}
        />
      </div>
    );
  }
}

export default withToastManager(withRouter(withDocumentTitle(PedidoList, 'Pedidos')));
