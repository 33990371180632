export type WeekDaySelection = { key: string; label: string; checked?: boolean };

// empty position 0 is added because the days start from position 1
export const days: WeekDaySelection[] = [
  {
    key: 'LU',
    label: 'Lunes',
  },
  {
    key: 'MA',
    label: 'Martes',
  },
  {
    key: 'MI',
    label: 'Miércoles',
  },
  {
    key: 'JU',
    label: 'Jueves',
  },
  {
    key: 'VI',
    label: 'Viernes',
  },
  {
    key: 'SA',
    label: 'Sábado',
  },
];

/**
 * Return a string with delivery days
 * @param {JSON string} data delivery days information
 */
export function parseDayData(data) {
  if (data && data !== '') {
    const parseData = JSON.parse(data);
    let daysToShow = '';

    parseData.forEach((keyDay) => {
      const weekDay = days.find((day) => day.key === keyDay);
      daysToShow = daysToShow + ` ${weekDay?.label}`;
    });

    return daysToShow;
  }
  return '';
}

/**
 * Return an array with the selected days
 * @param {Array} deliveryDays delivery days information
 */
export function checkDayData(deliveryDays): WeekDaySelection[] {
  if (deliveryDays.length > 0) {
    const checkedDays = days.map((day) => {
      const isDaySelected = deliveryDays.some((d) => d === day.key);
      if (isDaySelected) {
        day.checked = true;
      } else {
        day.checked = false;
      }
      return day;
    });

    return checkedDays;
  }

  return days.map((day) => {
    day.checked = false;
    return day;
  });
}
